import { QuotationRequest } from "../../helpers/interfaces"
import { Descriptions } from "antd"
import { turnaroundTime } from "../../helpers/data/projectData"

const { Item } = Descriptions

export default function ViewQuotationDetails({
   quote,
   show,
}: {
   quote: QuotationRequest
   show: boolean
}) {
   return (
      <>
         {show && quote && (
            <div>
               <Descriptions bordered>
                  <Item label='Name'>{quote.name}</Item>
                  <Item label='Description' span={2}>
                     {quote.description}
                  </Item>

                  <Item label='Quantity'>{quote.qty.join(", ")}</Item>
                  <Item label='Number of Print Versions'>
                     {quote.numberOfPrintVersion}
                  </Item>
                  <Item label='Turn Around Time'>
                     {turnaroundTime[quote.turnaroundTime]}
                  </Item>

                  <Item label='Length'>{quote.length}</Item>
                  <Item label='Width'>{quote.width}</Item>
                  <Item label='Height'>{quote.height}</Item>

                  <Item label='Packaging Style' span={3}>
                     {quote.packagingStyle}
                  </Item>
                  <Item label='Paper Stock' span={3}>
                     {quote.paperStock}
                  </Item>
                  <Item label='Outside Print Specifications' span={3}>
                     {quote.outsidePrintSpec}
                  </Item>

                  <Item label='Outside Coating' span={3}>
                     {quote.outsideCoating}
                  </Item>
                  <Item label='Inside Print Specifications' span={3}>
                     {quote.insidePrintSpec}
                  </Item>
                  <Item label='Inside Coating' span={3}>
                     {quote.insideCoating}
                  </Item>

                  <Item label='Special Effects' span={3}>
                     {quote.specialEffects.join(", ")}
                  </Item>

                  <Item label='Additional Details' span={3}>
                     {quote.additionalDetails}
                  </Item>
               </Descriptions>
            </div>
         )}
      </>
   )
}
