import { Button, Form, Modal, message } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { useState } from "react"
import ImageUploader from "../../combined/ImageUploader"
import { UploadType } from "../../../../helpers/uploadFileHelper"
import { FileReadOptions } from "fs/promises"
import {
   Order,
   OrderActivity,
   OrderActivityType,
   OrderStatus,
} from "../../../../helpers/types/order"
import { serverTimestamp } from "firebase/firestore"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../../../helpers/firestoreHelper"
import { EvergoCollections } from "../../../../helpers/types/firestore"

export default function DeliverOrderModal({
   orderId,
   deliverAgain,
   userId,
}: {
   orderId: string
   deliverAgain: boolean
   userId: string
}) {
   const [show, setShow] = useState(false)
   const [delivering, setDelivering] = useState(false)
   const [uploadedFiles, setUploadedFiles] = useState([])
   const [form] = Form.useForm()
   const [uploadingFiles, setUploadingFiles] = useState(false)
   const [fileList, setFileList] = useState([])
   return (
      <>
         <Modal
            title={deliverAgain ? "Deliver Order Again" : "Deliver Order"}
            onCancel={() => {
               if (delivering || uploadingFiles) return

               setShow(false)
            }}
            okText={deliverAgain ? "Deliver Again" : "Deliver"}
            okButtonProps={{
               htmlType: "submit",
               form: "deliverOrderForm",
               size: "large",
               loading: delivering,
               disabled: uploadingFiles,
            }}
            cancelButtonProps={{
               size: "large",
               disabled: delivering || uploadingFiles,
            }}
            open={show}>
            <div className=''>
               <Form
                  form={form}
                  id='deliverOrderForm'
                  onFinish={async (v) => {
                     try {
                        const newOrderActivity: OrderActivity = {
                           attachedFiles: uploadedFiles.map((f) => f.filename),
                           // @ts-ignore
                           createdAt: serverTimestamp(),
                           // @ts-ignore
                           updatedAt: serverTimestamp(),
                           description: v.description,
                           from: "admin",
                           orderId,
                           userId,
                           type: OrderActivityType.orderDelivered,
                        }

                        setDelivering(true)

                        await addCustomDocument(
                           EvergoCollections.ordersActivities,
                           newOrderActivity
                        )

                        try {
                           await updateCustomDocument(
                              EvergoCollections.orders,
                              orderId,
                              // @ts-ignore
                              {
                                 status: OrderStatus.customerReviewPending,
                                 updatedAt: serverTimestamp(),
                              } as Order
                           )

                           form.resetFields()
                           setUploadedFiles([])
                           setFileList([])
                        } catch (e) {
                           console.log(e)
                        }
                        setShow(false)
                        message.success("Order delivered successfully")
                     } catch (E) {
                        message.error("Error delivering order")
                        console.log(E)
                     }

                     setDelivering(false)
                  }}
                  size='large'
                  layout='vertical'>
                  <div className='flex flex-col'>
                     <Form.Item
                        name={"description"}
                        label='Description'
                        rules={[
                           {
                              required: true,
                              message: "Please enter description",
                           },
                           {
                              min: 10,
                              message:
                                 "Description must be at least 10 characters",
                           },
                           {
                              max: 5000,
                              message:
                                 "Description must be at most 1000 characters",
                           },
                        ]}>
                        <TextArea rows={2} placeholder='Enter description' />
                     </Form.Item>
                     <label>Upload Files</label>
                     <ImageUploader
                        type={UploadType.orders}
                        userId={userId}
                        fileList={fileList}
                        onFileListChange={(f) => setFileList(f)}
                        onChange={(f) => setUploadedFiles(f)}
                        onUploadStateChange={(u) => setUploadingFiles(u)}
                     />
                  </div>
               </Form>
            </div>
         </Modal>
         <Button onClick={() => setShow(true)} size='large' type='primary'>
            {deliverAgain ? "Deliver Again" : "Deliver Now"}
         </Button>
      </>
   )
}
