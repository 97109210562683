import React from "react"
import {
   QuotationRequest,
   QuotationRequestType,
   QuoteRequestStatus,
   UserView,
} from "../../../helpers/interfaces"
import Title from "./Title"
import { quotationRequestIssueRejectionType } from "../../../helpers/data/projectData"
import DateRenderer from "../user/combined/DateRenderer"
import { CloseCircleFilled, ExclamationCircleFilled } from "@ant-design/icons"
import ImageAssetPreview from "./ImageAssetPreview"

export default function QuoteRequestRejectionView({
   view,
   quoteRequest,
}: {
   view: UserView
   quoteRequest: QuotationRequest
}) {
   quoteRequest.files ??= []
   if (quoteRequest.status !== QuoteRequestStatus.rejected) {
      return <p>This view is only available for rejected quote requests</p>
   }

   return (
      <div className='border-orange-500 shadow-md rounded-lg border border-solid flex flex-col space-y-2 p-2'>
         <div className='flex flex-row justify-between'>
            <div className='flex flex-row space-x-2 items-center'>
               <ExclamationCircleFilled className='text-orange-400 text-xl ' />
               <Title
                  text='Modification Requested'
                  className='text-orange-400'
               />
            </div>
            <p className='text-base'>
               <DateRenderer full date={quoteRequest.updatedAt} />
            </p>
         </div>
         {/* view rejection issue type and remarks */}
         <div className='flex flex-col space-y-2'>
            <div className='flex flex-row space-x-2 items-center'>
               <h1 className='font-semibold text-base'>Rejection Type: </h1>
               <p className='text-base'>
                  {quotationRequestIssueRejectionType?.[
                     quoteRequest?.issue?.type ?? "date"
                  ] ?? "Unknown"}
               </p>
            </div>

            <div className='flex flex-col space-y-1'>
               <h1 className='font-semibold text-base'>Remarks: </h1>
               <p className='text-base'>{quoteRequest?.issue?.remarks}</p>
            </div>
            {quoteRequest.files.length === 0 ? (
               <h1>No Assets Attached</h1>
            ) : (
               <></>
            )}
            <div className='flex flex-row space-x-2 flex-wrap'>
               {quoteRequest.files.map((file, index) => (
                  <ImageAssetPreview
                     allowDownload={true}
                     userId={quoteRequest.userId}
                     key={"quote-file-" + index}
                     id={file}
                  />
               ))}
            </div>
         </div>
      </div>
   )
}
