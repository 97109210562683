import {
   Breadcrumb,
   Button,
   Form,
   Input,
   Popconfirm,
   Space,
   Table,
   message,
} from "antd"
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem"
import React, { useEffect, useState } from "react"
import Title from "../../combined/Title"
import UserFormField from "../combined/UserFormField"
import { Order, OrderStatus } from "../../../../helpers/types/order"
import OrderStatusFormField from "../../combined/OrderStatusFormField"
import { useNavigate } from "react-router-dom"
import { useCollection } from "react-firebase-hooks/firestore"
import {
   collection,
   getFirestore,
   orderBy,
   query,
   where,
} from "firebase/firestore"
import DateRenderer from "../../user/combined/DateRenderer"
import UserOverview from "../combined/UserOverview"
import OrderStatusRenderer from "../../user/combined/OrderStatusRenderer"
import { DeleteFilled, EyeFilled } from "@ant-design/icons"
import { deleteCustomDocument } from "../../../../helpers/firestoreHelper"

type OrderFilter = {
   userId: string
   title: string
   status: OrderStatus
}

export default function AdminOrdersScreen() {
   const navigate = useNavigate()
   const [form] = Form.useForm()

   const [ordersFilter, setOrdersFilter] = useState<OrderFilter>({
      status: OrderStatus.created,
      title: "",
      userId: "",
   })

   const [orders, ordersLoading, ordersError] = useCollection(
      query(
         collection(getFirestore(), "orders"),
         where("status", "==", ordersFilter.status),
         orderBy("createdAt", "desc")
      )
   )

   const ordersData =
      orders?.docs.map((doc) => {
         return {
            ref: doc.id,
            ...doc.data(),
         } as Order
      }) ?? []
   const columns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Date Created",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text: any, record: Order) => {
            return <DateRenderer date={record.createdAt} />
         },
      },
      {
         title: "Submitted By",
         dataIndex: "userId",
         key: "user",
         render: (userId: string) => <UserOverview userId={userId} />,
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (status: string) => (
            <OrderStatusRenderer status={status as OrderStatus} />
         ),
      },
      {
         title: "Actions",
         key: "actions",
         render: (_: any, record: Order) => {
            return (
               <Space>
                  <Button
                     onClick={() => {
                        navigate(`/admin/dashboard/orders/${record.ref}`)
                     }}
                     type='primary'
                     icon={<EyeFilled />}
                  />
                  <Popconfirm
                     onConfirm={async () => {
                        try {
                           await deleteCustomDocument("orders", record.id)
                           message.success("Order deleted successfully")
                        } catch (e) {
                           message.error("Error deleting order")
                        }
                     }}
                     title="Are you sure? This can't be undone.">
                     <Button danger type='primary' icon={<DeleteFilled />} />
                  </Popconfirm>
               </Space>
            )
         },
      },
   ]

   useEffect(() => {
      if (form) {
         form.setFieldValue("orderStatus", OrderStatus.created)
      }
   }, [form])
   return (
      <div className='flex flex-col space-y-2 flex-1 overflow-auto'>
         <div>
            <Breadcrumb>
               <BreadcrumbItem>Admin</BreadcrumbItem>
               <BreadcrumbItem>Orders</BreadcrumbItem>
            </Breadcrumb>
         </div>
         <Title text='Orders' />

         <div className='flex flex-col'>
            <Form
               form={form}
               onFinish={(v) => {
                  setOrdersFilter({
                     status: v.orderStatus,
                     title: v.title,
                     userId: v.userId,
                  })
               }}
               size='large'>
               <div className='flex flex-row space-x-2'>
                  <Form.Item name={"title"}>
                     <Input placeholder='Search by title' />
                  </Form.Item>
                  <UserFormField />
                  <OrderStatusFormField />
                  <Form.Item>
                     <Button htmlType='submit' type='primary'>
                        Search
                     </Button>
                  </Form.Item>
                  <Form.Item>
                     <Button
                        onClick={() => {
                           setOrdersFilter({
                              status: OrderStatus.submitted,
                              title: "",
                              userId: "",
                           })

                           form.setFieldValue(
                              "orderStatus",
                              OrderStatus.submitted
                           )

                           form.setFieldValue("title", "")
                           form.setFieldValue("userId", null)
                        }}
                        danger>
                        Clear
                     </Button>
                  </Form.Item>
               </div>
            </Form>
         </div>

         <Title text='New Quotations List' />
         <div>
            <Table
               rowKey={(record) => record.id}
               dataSource={ordersData.filter((q) => {
                  if (ordersFilter.title) {
                     return q.title
                        .toLowerCase()
                        .includes(ordersFilter.title.toLowerCase())
                  }

                  if (ordersFilter.userId && ordersFilter.userId.length > 0) {
                     return q.userId === ordersFilter.userId
                  }

                  if (ordersFilter.status) {
                     return q.status === ordersFilter.status
                  }

                  return true
               })}
               columns={columns}
               loading={ordersLoading}
            />
         </div>
      </div>
   )
}
