import { PlusCircleFilled } from "@ant-design/icons"
import { Button, Form, Input, Modal, message } from "antd"
import React, { useState } from "react"
import { addNewAdmin } from "../../../../helpers/admin/adminsHelper"

interface IAddUpdateAdminProps {
   type: "add" | "update"
}

export default function AddUpdateAdmin({ type }: IAddUpdateAdminProps) {
   const [show, setShow] = useState(false)
   const [adding, setAdding] = useState(false)
   return (
      <>
         <Modal
            open={show}
            onCancel={() => setShow(false)}
            cancelButtonProps={{
               size: "large",
            }}
            okButtonProps={{
               size: "large",
               loading: adding,
               form: "add-update-admin-form",
               htmlType: "submit",
            }}
            title={(type === "add" ? "Add " : "Update ") + "Admin"}>
            <Form
               onFinish={async (v) => {
                  setAdding(true)
                  const response = await addNewAdmin(v)

                  if (response.isSuccess) {
                     message.success(response.message)
                     setShow(false)
                  } else {
                     message.error(response.message)
                  }

                  setAdding(false)
               }}
               name='add-update-admin-form'
               layout='vertical'
               size='large'>
               <div className='flex flex-col space-y-4 py-4'>
                  <Form.Item
                     style={{
                        marginBottom: "0",
                     }}
                     rules={[
                        {
                           required: true,
                           message: "Please input your name!",
                        },
                        {
                           min: 2,
                           message: "Name must be at least 2 characters long!",
                        },
                        {
                           max: 50,
                           message: "Name must be at most 50 characters long!",
                        },
                     ]}
                     name={"name"}>
                     <Input placeholder='Enter name' />
                  </Form.Item>
                  <Form.Item
                     name={"email"}
                     rules={[
                        {
                           required: true,
                           message: "Please input your email!",
                        },
                        {
                           type: "email",
                           message: "Please input a valid email!",
                        },
                     ]}>
                     <Input placeholder='Enter email' />
                  </Form.Item>
                  <Form.Item
                     name={"password"}
                     rules={[
                        {
                           required: true,
                           message: "Please input your password!",
                        },
                        {
                           min: 6,
                           message:
                              "Password must be at least 6 characters long!",
                        },
                        {
                           max: 50,
                           message:
                              "Password must be at most 50 characters long!",
                        },
                     ]}>
                     <Input.Password placeholder='Enter password' />
                  </Form.Item>
               </div>
            </Form>
         </Modal>
         <Button
            icon={type === "add" ? <PlusCircleFilled /> : <></>}
            type={type === "add" ? "primary" : "link"}
            onClick={() => {
               setShow(true)
            }}>
            {type === "add" ? "Add New Admin" : "Update"}
         </Button>
      </>
   )
}
