import { Button, Form, Input, Upload, message } from "antd"
import React, { useEffect, useState } from "react"
import { UserProfile } from "../../../../helpers/interfaces"
import { useDocument } from "react-firebase-hooks/firestore"
import { doc, getFirestore } from "firebase/firestore"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import Loading from "../../../Loading"
import { getAuth, updateEmail, updateProfile } from "firebase/auth"
import { updateCustomDocument } from "../../../../helpers/firestoreHelper"
import Section from "../../combined/Section"
import {
   UploadType,
   getNewFileName,
   getNewFileNameWithoutUserId,
} from "../../../../helpers/uploadFileHelper"
import ImageAssetPreview from "../../combined/ImageAssetPreview"
import { getStorage, ref, uploadBytes } from "firebase/storage"

export default function ProfileScreen() {
   const [profileSnapshot, profileLoading, profileError] = useDocument(
      doc(getFirestore(), "users", getCurrentUserId())
   )
   const [changingEmail, setChangingEmail] = useState(false)
   const [updatingProfilePicture, setUpdatingProfilePicture] = useState(false)
   const [updatingProfile, setUpdatingProfile] = useState(false)
   const profile = (profileSnapshot?.data?.() ?? {}) as UserProfile

   useEffect(() => {
      let profile = (profileSnapshot?.data?.() ?? {}) as UserProfile
      if (profile) {
         emailForm.setFieldValue("email", profile.email)
         profileForm.setFieldValue("fullname", profile.fullname)
         profileForm.setFieldValue("phoneNumber", profile.phoneNumber)
      }
   }, [profileSnapshot])

   const [emailForm] = Form.useForm()
   const [imageFileList, setImageFileList] = useState([])
   const [profileForm] = Form.useForm()
   return (
      <div className='flex flex-col flex-1 overflow-auto'>
         <Loading loading={profileLoading}>
            <div className='flex flex-col space-y-4'>
               <Section title='Update Profile Picture'>
                  <div className='flex flex-col p-4 space-y-2'>
                     <Upload
                        showUploadList={false}
                        accept='.png,.jpg,.jpeg'
                        listType='picture-circle'
                        disabled={updatingProfilePicture}
                        onChange={async (f) => {
                           setUpdatingProfilePicture(true)
                           message.loading("Uploading image...")

                           let fileName = getNewFileName(
                              f.file.name,
                              UploadType.profilePicture,
                              getCurrentUserId()
                           )
                           const storageRef = ref(getStorage(), fileName)

                           try {
                              await uploadBytes(
                                 storageRef,
                                 // @ts-ignore
                                 f.file,
                                 {
                                    contentType: `image/${
                                       fileName.split(".")[1]
                                    }`,
                                 }
                              )

                              await updateCustomDocument(
                                 "users",
                                 getCurrentUserId(),
                                 {
                                    image: getNewFileNameWithoutUserId(
                                       fileName
                                    ),
                                 }
                              )
                              message.destroy()

                              message.success("Image uploaded successfully.")
                           } catch (e) {
                              message.destroy()
                              message.error("Unable to upload image.")
                           }
                           setUpdatingProfilePicture(false)
                        }}
                        beforeUpload={(f) => {
                           return false
                        }}>
                        <div className='overflow-hidden rounded-full m-2'>
                           {profile && profile.image ? (
                              <ImageAssetPreview
                                 borderLess={true}
                                 allowPreview={false}
                                 allowDownload={false}
                                 userId={getCurrentUserId()}
                                 id={profile.image}
                              />
                           ) : (
                              "Upload"
                           )}
                        </div>
                     </Upload>
                  </div>
               </Section>
               <Section title='Update Email Address'>
                  <div className='p-4'>
                     <Form
                        size='large'
                        form={emailForm}
                        onFinish={async (v) => {
                           setChangingEmail(true)
                           try {
                              await updateEmail(getAuth().currentUser, v.email)
                              await updateCustomDocument(
                                 "users",
                                 getCurrentUserId(),
                                 {
                                    email: v.email,
                                 }
                              )
                              message.success("Email updated successfully.")
                           } catch (e) {
                              emailForm.setFieldValue("email", profile.email)
                              message.error("Unable to update email address.")
                           }
                           setChangingEmail(false)
                        }}
                        layout='vertical'>
                        <div className='flex flex-col space-y-2'>
                           <Form.Item
                              className='flex-1'
                              label='Email'
                              name={"email"}
                              rules={[
                                 {
                                    required: true,
                                    message: "Email is required.",
                                 },
                                 {
                                    type: "email",
                                    message: "Email is invalid.",
                                 },
                              ]}>
                              <Input
                                 placeholder='Enter email address'
                                 type='email'
                              />
                           </Form.Item>
                           <Form.Item>
                              <Button
                                 loading={changingEmail}
                                 className='w-fit'
                                 type='primary'
                                 htmlType='submit'>
                                 Update Email
                              </Button>
                           </Form.Item>
                        </div>
                     </Form>
                  </div>
               </Section>
               <Section title='Update Profile'>
                  <div className='p-4'>
                     <Form
                        layout='vertical'
                        size='large'
                        form={profileForm}
                        onFinish={async (v) => {
                           setUpdatingProfile(true)
                           try {
                              await updateProfile(getAuth().currentUser, {
                                 displayName: v.fullname,
                              })
                              await updateCustomDocument(
                                 "users",
                                 getCurrentUserId(),
                                 {
                                    fullname: v.fullname,
                                    phoneNumber: v.phoneNumber,
                                 }
                              )
                              message.success("Profile updated successfully. ")
                           } catch (e) {
                              message.error("Error updating profile.")
                           }
                           setUpdatingProfile(false)
                        }}>
                        <Form.Item
                           label='Full Name'
                           name={"fullname"}
                           rules={[
                              {
                                 required: true,
                                 message: "Enter full name",
                              },
                              {
                                 min: 3,
                                 message:
                                    "Name should be atleast 3 characters long",
                              },
                              {
                                 max: 50,
                                 message:
                                    "Name should not exceed 50 characters",
                              },
                           ]}>
                           <Input placeholder='Enter full name' />
                        </Form.Item>
                        <Form.Item
                           name={"phoneNumber"}
                           label='Phone number'
                           rules={[
                              {
                                 required: true,
                                 message: "Enter full name",
                              },
                              {
                                 min: 10,
                                 message:
                                    "Phone number should be atleast 10 characters long",
                              },
                              {
                                 max: 15,
                                 message:
                                    "Phone number should not exceed 15 characters",
                              },
                           ]}>
                           <Input placeholder='Enter phone number' />
                        </Form.Item>

                        <Form.Item>
                           <Button
                              loading={updatingProfile}
                              htmlType='submit'
                              type='primary'>
                              Update Profile
                           </Button>
                        </Form.Item>
                     </Form>
                  </div>
               </Section>
            </div>
         </Loading>
      </div>
   )
}
