// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyDIeFYelKlBTzy7X6HgoBT_4J_MOd7STQg",
   authDomain: "evergo-382714.firebaseapp.com",
   projectId: "evergo-382714",
   storageBucket: "evergo-382714.appspot.com",
   messagingSenderId: "186751360673",
   appId: "1:186751360673:web:c6163d51a3b7e7adb01ae0",
   measurementId: "G-GRT29N62NR",
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const defaultRegion = "northamerica-northeast1"
const analytics = getAnalytics(app)
