import {
   Button,
   Divider,
   Form,
   Input,
   Modal,
   Radio,
   Select,
   Steps,
   message,
} from "antd"
import React, { useRef, useState } from "react"
import { startOrder } from "../../../../helpers/user/orderHelper"
import ImageUploader, {
   AllowFileType,
   UploadedFile,
} from "../../combined/ImageUploader"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import { UploadType } from "../../../../helpers/uploadFileHelper"
import { canadaStates } from "../../../../helpers/data/projectData"
import {
   OrderPaymentOptions,
   OrderPickupOption,
   StartOrderData,
} from "../../../../helpers/types/order"

export default function StartOrder(
   { projectId, quoteId },
   { projectId: stirng, quoteId: string }
) {
   const [showOrderDialog, setShowOrderDialog] = useState(false)
   const [startingOrder, setStartingOrder] = useState(false)

   const [showBankTransferOption, setShowBankTransferOption] = useState(false)
   const [showAddressFillup, setShowAddressFillup] = useState(false)
   const [orderFiles, setOrderFiles] = useState<UploadedFile[]>([])
   const [ordersFilesUploading, setOrdersFilesUploading] = useState(false)
   const [orderFileList, setOrderFileList] = useState([])
   const [bankProofFiles, setBankProofFiles] = useState<UploadedFile[]>([])
   const [bankProofFilesUploading, setBankProofFilesUploading] = useState(false)
   const [bankProofFileList, setBankProofFileList] = useState([])

   const startNewOrder = async (values) => {
      let startOrderData: StartOrderData = {
         projectId: projectId,
         quoteId: quoteId,
         paymentOption: values.paymentType,
         pickupOption: values.pickupType,
         deliveryAddress: values.deliveryAddress,
         description: values.description,
         files: orderFiles.map((o) => o.filename),
      }

      if (values.paymentType === OrderPaymentOptions.bankTransfer) {
         startOrderData.bankPayment = {
            attachments: bankProofFiles.map((f) => f.filename),
         }
      }

      setStartingOrder(true)
      const t = await startOrder(startOrderData)
      setStartingOrder(false)
      if (t.isSuccess) {
         message.success(t.message)
      } else message.error(t.message)
   }

   const orderDetails = () => {
      return (
         <div className='flex flex-col'>
            <Divider orientation='left'>Enter Order Details</Divider>
            <Form.Item
               label='Description'
               name={"description"}
               rules={[
                  {
                     required: true,
                     message: "Please enter a description",
                  },
                  {
                     min: 5,
                     message: "Description must be atleast 5 characters long",
                  },
                  {
                     max: 5000,
                     message:
                        "Description must be less than 5000 characters long",
                  },
               ]}>
               <Input.TextArea placeholder='Description' rows={5} />
            </Form.Item>
            <label className='mb-1'>Files</label>
            <ImageUploader
               type={UploadType.orders}
               onFileAdd={(f) => {
                  setOrderFiles([...orderFiles, f])
               }}
               onFileRemove={(uid) => {
                  setOrderFiles(orderFiles.filter((o) => o.uid !== uid))
               }}
               allowed={AllowFileType.all}
               fileList={orderFileList}
               onFileListChange={(a) => setOrderFileList(a)}
               userId={getCurrentUserId()}
               onUploadStateChange={(a) => setOrdersFilesUploading(a)}
            />
         </div>
      )
   }

   const shippingSelection = () => {
      return (
         <div className='flex flex-col'>
            <Divider orientation='left'>Select Shipping Type</Divider>
            <Form.Item
               name={"pickupType"}
               rules={[
                  {
                     required: true,
                     message: "Please select a pickup option",
                  },
               ]}
               label='Select Pickup'>
               <Radio.Group>
                  <Radio.Button value={OrderPickupOption.self}>
                     Self Pickup
                  </Radio.Button>
                  <Radio.Button value={OrderPickupOption.delivery}>
                     Delivery (29.99$)
                  </Radio.Button>
               </Radio.Group>
            </Form.Item>
         </div>
      )
   }

   const deliveryDetails = () => {
      return (
         <div className='grid grid-cols-2 gap-x-2'>
            <Divider orientation='left' className='col-span-2'>
               Shipping Information
            </Divider>
            <Form.Item
               name={["deliveryAddress", "name"]}
               rules={[
                  {
                     required: true,
                     message: "Please enter your name",
                  },
                  {
                     min: 3,
                     message: "Name must be atleast 3 characters long",
                  },
                  {
                     max: 50,
                     message: "Name must be less than 50 characters long",
                  },
               ]}
               label='Name'>
               <Input placeholder='Enter Name' />
            </Form.Item>

            <Form.Item
               name={["deliveryAddress", "address1"]}
               rules={[
                  {
                     required: true,
                     message: "Please enter your address",
                  },
               ]}
               label='Address 1'>
               <Input placeholder='Address 1' />
            </Form.Item>
            <Form.Item name={["deliveryAddress", "address2"]} label='Address 2'>
               <Input placeholder='Address 2' />
            </Form.Item>
            <Form.Item
               name={["deliveryAddress", "postalCode"]}
               rules={[
                  {
                     required: true,
                     message: "Please enter your postal code",
                  },
                  {
                     min: 3,
                     message: "Postal code must be atleast 3 characters",
                  },
                  {
                     max: 50,
                     message: "Postal code must be less than 50 characters",
                  },
               ]}
               label='Postal Code'>
               <Input placeholder='Postal Code' />
            </Form.Item>

            <Form.Item
               label='State'
               rules={[
                  {
                     required: true,
                     message: "Please select a state",
                  },
               ]}
               name={["deliveryAddress", "state"]}>
               <Select
                  allowClear
                  showSearch
                  placeholder='Select State'
                  options={canadaStates}
               />
            </Form.Item>

            <Form.Item
               name={["deliveryAddress", "city"]}
               rules={[
                  {
                     required: true,
                     message: "Please enter your city",
                  },
               ]}
               label='City'>
               <Input placeholder='Enter City' />
            </Form.Item>
         </div>
      )
   }

   const paymentOption = () => {
      return (
         <div className='flex flex-col'>
            <Divider orientation='left'>Select Payment Option</Divider>
            <Form.Item
               rules={[
                  {
                     required: true,
                     message: "Please select a payment option",
                  },
               ]}
               label='Select Payment Type'
               name={"paymentType"}>
               <Radio.Group>
                  <Radio.Button value={OrderPaymentOptions.cash}>
                     Cash
                  </Radio.Button>
                  <Radio.Button value={OrderPaymentOptions.bankTransfer}>
                     Bank Transfer
                  </Radio.Button>
               </Radio.Group>
            </Form.Item>
         </div>
      )
   }

   const bankTransferOption = () => {
      return (
         <div className='flex flex-col space-y-3'>
            <Divider orientation='left'>Enter Payment Proof</Divider>
            <h2 className='text-red-400'>
               * Kindly pay on the provided bank details and send prove as
               attachment.
            </h2>
            <h1 className='font-bold'>Bank Details</h1>
            <h2 className='font-semibold'>IBAN: TEST123456789012345</h2>

            <label>Upload File</label>
            <ImageUploader
               type={UploadType.bankPaymentProof}
               onFileAdd={(f) => setBankProofFiles([...bankProofFiles, f])}
               onFileRemove={(uid) =>
                  setBankProofFiles(
                     bankProofFiles.filter((bf) => bf.uid !== uid)
                  )
               }
               allowed={AllowFileType.all}
               fileList={bankProofFileList}
               onFileListChange={(fl) => setBankProofFileList(fl)}
               userId={getCurrentUserId()}
               onUploadStateChange={(a) => setBankProofFilesUploading(a)}
            />
         </div>
      )
   }

   const steps = [
      {
         title: "Order Details",
         content: orderDetails,
      },
      {
         title: "Shipping",
         content: shippingSelection,
      },
      showAddressFillup
         ? {
              title: "Delivery Details",
              content: deliveryDetails,
           }
         : null,
      {
         title: "Payment",
         content: paymentOption,
      },
      showBankTransferOption
         ? {
              title: "Bank Transfer",
              content: bankTransferOption,
           }
         : null,
   ].filter((a) => a)

   const [currentStep, setCurrentStep] = useState(0)
   const items = steps.map((item) => ({ key: item.title, title: item.title }))

   const formData = useRef({
      paymentType: OrderPaymentOptions.cash,
      pickupType: OrderPickupOption.self,
   })

   const [form] = Form.useForm()
   return (
      <>
         <Modal
            cancelButtonProps={{
               size: "large",
               disabled: startingOrder || bankProofFilesUploading,
            }}
            okButtonProps={{
               size: "large",
               form: "startOrderForm",
               htmlType: "submit",
               loading: startingOrder,
            }}
            okText='Start Now'
            onCancel={() => {
               if (
                  ordersFilesUploading ||
                  bankProofFilesUploading ||
                  startingOrder
               ) {
                  return
               }

               setShowOrderDialog(false)
            }}
            footer={null}
            width={"800px"}
            open={showOrderDialog}
            title='Start New Order'>
            <div className='py-5 px-2'>
               <Form
                  form={form}
                  initialValues={formData}
                  size='large'
                  onFinish={(v) => {
                     formData.current = {
                        ...formData.current,
                        ...v,
                     }

                     if (currentStep === steps.length - 1) {
                        if (
                           showBankTransferOption &&
                           bankProofFiles.length === 0
                        ) {
                           message.error("Please upload bank proof")
                           return
                        }
                        startNewOrder(formData.current)
                     } else {
                        setCurrentStep(currentStep + 1)
                     }
                  }}
                  name='startOrderForm'
                  onValuesChange={(e) => {
                     if (Object.keys(e).includes("paymentType")) {
                        setShowBankTransferOption(
                           e.paymentType === OrderPaymentOptions.bankTransfer
                        )
                     }

                     if (Object.keys(e).includes("pickupType")) {
                        setShowAddressFillup(
                           e.pickupType === OrderPickupOption.delivery
                        )
                     }
                  }}
                  layout='vertical'>
                  <div className='flex flex-col'>
                     <Steps current={currentStep} items={items} />
                     <div className='my-5 h-[400px] overflow-auto'>
                        {steps[currentStep].content()}
                     </div>
                     <div className='flex flex-row justify-between'>
                        <Button
                           disabled={
                              currentStep === 0 ||
                              ordersFilesUploading ||
                              bankProofFilesUploading ||
                              startingOrder
                           }
                           type='primary'
                           onClick={() => {
                              setCurrentStep(currentStep - 1)
                           }}
                           size='large'>
                           Back
                        </Button>
                        <Button
                           onClick={() => {
                              // setCurrentStep(currentStep + 1)
                              form.submit()
                           }}
                           disabled={
                              ordersFilesUploading ||
                              bankProofFilesUploading ||
                              (showBankTransferOption &&
                                 bankProofFiles.length === 0 &&
                                 currentStep === steps.length - 1)
                           }
                           loading={startingOrder}
                           type='primary'
                           size='large'>
                           {currentStep === steps.length - 1
                              ? "Start Order"
                              : "Next"}
                        </Button>
                     </div>
                  </div>
               </Form>
            </div>
         </Modal>
         <Button type='primary' onClick={() => setShowOrderDialog(true)}>
            Start New Order
         </Button>
      </>
   )
}
