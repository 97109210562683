import { Tag } from "antd"
import React from "react"
import { QuoteStatus } from "../../../../helpers/interfaces"
import { quotationOptions } from "../../../../helpers/defaultData"

export default function QuoteStatusRenderer({
   status,
}: {
   status: QuoteStatus
}) {
   return (
      <Tag
         color={
            status === QuoteStatus.accepted
               ? "green"
               : status === QuoteStatus.modificationRequested
               ? "orange"
               : status === QuoteStatus.replaced
               ? "red-inverse"
               : status === QuoteStatus.orderCreated
               ? "green-inverse"
               : "blue"
         }
         className='uppercase'>
         {quotationOptions.find((q) => q.value === status)?.label}
      </Tag>
   )
}
