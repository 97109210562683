import React from "react"
import AddUpdateAdmin from "./AddUpdateAdmin"
import { Admin } from "../../../../helpers/interfaces"
import { Button, Popconfirm, Space, Table, message } from "antd"
import { DeleteFilled, LockFilled, UnlockFilled } from "@ant-design/icons"
import {
   removeAdmin,
   toggleActivateAdmin,
} from "../../../../helpers/admin/adminsHelper"
import { useCollection } from "react-firebase-hooks/firestore"
import { collection, getFirestore, query } from "firebase/firestore"

export default function AdminsScreen() {
   const [admins, adminsLoading, adminsError] = useCollection(
      collection(getFirestore(), "admins")
   )

   if (adminsError) {
      console.error(adminsError)
   }

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Status",
         dataIndex: "disabled",
         render: (disabled: boolean) => (
            <div
               className={
                  "w-[15px] h-[15px] rounded-full " +
                  (disabled ? "bg-red-500" : "bg-green-500")
               }></div>
         ),
      },
      {
         title: "Action",
         key: "action",
         render: (text: any, admin: Admin) => {
            return (
               <Space>
                  <Popconfirm
                     onConfirm={async () => {
                        const response = await toggleActivateAdmin(admin.id)

                        if (response.isSuccess) {
                           message.success(response.message)
                        } else {
                           message.error(response.message)
                        }
                     }}
                     title='Are you sure?'>
                     <Button
                        type='primary'
                        icon={
                           admin.disabled ? <UnlockFilled /> : <LockFilled />
                        }
                     />
                  </Popconfirm>
                  <Popconfirm
                     onConfirm={async () => {
                        const response = await removeAdmin(admin.id)

                        if (response.isSuccess) {
                           message.success(response.message)
                        } else {
                           message.error(response.message)
                        }
                     }}
                     title='Are you sure?'>
                     <Button danger icon={<DeleteFilled />} />
                  </Popconfirm>
               </Space>
            )
         },
      },
   ]
   return (
      <div className='flex flex-col flex-1 space-y-2'>
         <div className='flex flex-row justify-end'>
            <AddUpdateAdmin type='add' />
         </div>
         <div className='flex flex-1 flex-col'>
            <Table
               loading={adminsLoading}
               dataSource={
                  admins?.docs?.map(
                     (a) =>
                        ({
                           ...a.data(),
                           id: a.id,
                        } as Admin)
                  ) ?? []
               }
               columns={columns}
            />
         </div>
      </div>
   )
}
