import { useEffect, useState } from "react"
import { Form, Input, Button, Alert } from "antd"
import { Link, useNavigate } from "react-router-dom"
import {
   useAuthState,
   useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth"
import { getAuth } from "firebase/auth"
import Loading from "../Loading"

export default function LoginScreen() {
   const [loading, setLoading] = useState(false)
   const [loggingIn, setLoggingIn] = useState(false)
   const navigate = useNavigate()
   const [currentUser, loadingUser, userError] = useAuthState(getAuth())
   const [signin, user, signingIn, error] = useSignInWithEmailAndPassword(
      getAuth()
   )

   useEffect(() => {
      if (currentUser) {
         //get custom claims

         currentUser
            .getIdTokenResult()
            .then((idTokenResult) => {
               if (idTokenResult.claims?.admin) {
                  navigate("/admin/dashboard")
               } else {
                  navigate("/dashboard")
               }
            })
            .catch((e) => {
               console.log(e)
            })
      }
   }, [currentUser])

   if (loadingUser || currentUser) return <Loading />

   return (
      <div
         className='h-full w-full flex flex-row justify-end bg-no-repeat bg-cover'
         style={{
            backgroundImage: `url("${require("../../assets/accountback.png")}")`,
         }}>
         <div className='mr-[100px] flex flex-col justify-center items-center'>
            <div className='w-[400px] rounded-lg bg-white h-auto flex flex-col py-10 px-5'>
               <div className='space-y-4 mb-4'>
                  <h2 className='text-3xl mb-2 font-semibold text-center'>
                     Welcome to Evergo Packaging
                  </h2>
                  <h2 className='text-xl mb-2'>Login to Account</h2>
               </div>

               <Form
                  layout='vertical'
                  size='large'
                  onFinish={(v) => {
                     signin(v.email, v.password)
                  }}
                  name='loginForm'>
                  <div className='flex flex-col'>
                     <Form.Item
                        name={"email"}
                        rules={[
                           {
                              required: true,
                              message: "Please input your email address",
                           },
                           {
                              type: "email",
                              message: "Please input a valid email address",
                           },
                        ]}>
                        <Input placeholder='Enter your email address' />
                     </Form.Item>
                     <Form.Item
                        name={"password"}
                        rules={[
                           {
                              required: true,
                              message: "Please input your password",
                           },
                           {
                              min: 8,
                              message: "Password must be at least 8 characters",
                           },
                           {
                              max: 20,
                              message: "Password must be at most 20 characters",
                           },
                        ]}>
                        <Input.Password placeholder='Enter your password' />
                     </Form.Item>
                  </div>
                  {error && (
                     <Alert type='error' message={error.code} showIcon />
                  )}
                  <div className='mt-5 space-y-3 flex flex-col items-center'>
                     <Button
                        type='primary'
                        htmlType='submit'
                        loading={signingIn}
                        className='w-full'>
                        LOGIN
                     </Button>

                     <Link to={"/reset-password"}>Forgot Password?</Link>
                     <div className='w-full h-[1px] bg-gray-200' />

                     <Button
                        onClick={() => {
                           navigate("/register")
                        }}
                        className='w-full'>
                        REGISTER
                     </Button>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   )
}
