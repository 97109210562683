import {
   collection,
   doc,
   getFirestore,
   orderBy,
   query,
   serverTimestamp,
   where,
} from "firebase/firestore"
import React, { useState } from "react"
import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Button, Popconfirm, Tooltip, message } from "antd"
import { getCurrentUserId } from "../../helpers/userAuthHelpers"
import Loading from "../Loading"
import {
   Quotation,
   QuotationRequest,
   QuotationRequestType,
   QuoteRequestStatus,
   QuoteStatus,
   UserView,
} from "../../helpers/interfaces"
import ShortQuotationRequestView from "./ShortQuotationRequestView"
import AddUpdateQuotation from "../screens/user/viewprojects/AddUpdateQuotation"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../helpers/firestoreHelper"
import { startOrder } from "../../helpers/user/orderHelper"
import StartOrder from "../screens/user/order/StartOrder"

export default function ViewQuotationScreen() {
   const { id } = useParams()
   const location = useLocation()
   const navigate = useNavigate()
   const [startingOrder, setStartingOrder] = useState(false)

   const [quotationsSnapshot, quotationsLoading, quotationsError] =
      useCollection(
         query(
            collection(getFirestore(), "quote-requests"),
            where("quoteId", "==", id),
            where("userId", "==", getCurrentUserId()),
            orderBy("createdAt", "asc")
         )
      )

   const [quote, quoteLoading, quoteError] = useDocument(
      doc(getFirestore(), "quotes", id)
   )

   if (!id) {
      message.error("Invalid quotation request id")
      if (location.key) navigate(-1)
      else navigate("/")
      return
   }

   if (quotationsError) {
      message.error("Error loading quotation request")
      if (location.key) navigate(-1)
      else navigate("/")
      return
   }

   if (quotationsLoading || quoteLoading)
      return (
         <div className='flex w-full h-full justify-center items-center'>
            <Loading />
         </div>
      )

   const quotations = quotationsSnapshot?.docs.map((doc) => {
      return { ...doc.data(), id: doc.id }
   })

   const quoteData: Quotation = {
      ...quote?.data(),
      id: quote?.id,
   } as Quotation

   // get last submitted quotation request
   const lastSubmittedQuotationRequest = [...quotations]
      ?.reverse()
      .filter((q: QuotationRequest) => {
         return (
            q.status === QuoteRequestStatus.rejected &&
            q.type === QuotationRequestType.submission
         )
      })

   return (
      <div className='flex flex-col w-full'>
         <div className='flex flex-col space-y-4 overflow-auto'>
            {quotations.map((q: QuotationRequest) => {
               return (
                  <ShortQuotationRequestView
                     key={q.id + "short"}
                     view={UserView.user}
                     quotationRequest={q}
                  />
               )
            })}

            {quoteData.status === QuoteStatus.modificationRequested && (
               <div className='flex flex-row justify-center space-x-4'>
                  <AddUpdateQuotation
                     buttonProps={{
                        size: "large",
                     }}
                     // @ts-ignore
                     quotation={
                        lastSubmittedQuotationRequest.length > 0
                           ? lastSubmittedQuotationRequest[0]
                           : null
                     }
                     onUpdate={async (q) => {
                        delete q.id
                        q.userId = getCurrentUserId()
                        q.projectId = id
                        // @ts-ignore
                        q.createdAt = serverTimestamp()
                        // @ts-ignore
                        q.updatedAt = serverTimestamp()
                        q.submitType = "user"

                        q = {
                           ...q,
                           quoteId: quoteData.id,
                           status: QuoteRequestStatus.awaiting,
                           // @ts-ignore
                           createdAt: serverTimestamp(),
                           // @ts-ignore
                           updatedAt: serverTimestamp(),
                           projectId: quoteData.projectId,
                           submitType: "user",
                           type: QuotationRequestType.submission,
                        }

                        try {
                           await addCustomDocument("quote-requests", q)

                           await updateCustomDocument("quotes", quoteData.id, {
                              status: QuoteStatus.submitted,
                           } as Quotation)
                           message.success("Quotation submitted successfully")
                           return true
                        } catch (e) {
                           message.error("Error submitting quotation")

                           return false
                        }
                     }}
                     buttonType='primary'
                     showInitial={false}
                     type='update'
                     buttonText='Update & Re Submit'
                  />
               </div>
            )}

            {quoteData.status === QuoteStatus.accepted && (
               <div className='flex flex-row justify-center'>
                  <StartOrder
                     quoteId={quoteData.id}
                     projectId={quoteData.projectId}
                  />
               </div>
            )}

            {quoteData.status === QuoteStatus.orderCreated && (
               <div className='flex  items-center flex-row text-green-500 rounded-lg mb-5 justify-center shadow-lg border border-green-700 p-2 font-semibold'>
                  <h1>Order placed successfully.</h1>
                  <Button
                     onClick={() => {
                        navigate("/dashboard/orders/" + quoteData?.orderId)
                     }}
                     type='link'
                     size='small'>
                     View
                  </Button>
               </div>
            )}

            {quoteData.status !== QuoteStatus.modificationRequested &&
               quoteData.status !== QuoteStatus.accepted &&
               quoteData.status !== QuoteStatus.orderCreated && (
                  <div className='flex flex-row text-white rounded-lg mb-5 justify-center bg-primary p-2 font-semibold'>
                     {quoteData.status === QuoteStatus.submitted
                        ? "Awaiting for admin approval"
                        : "Replaced with Updated Quote"}
                  </div>
               )}
         </div>
      </div>
   )
}
