import { Button, ConfigProvider, Form, Modal, message } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { useState } from "react"
import ImageUploader from "../../combined/ImageUploader"
import { UploadType } from "../../../../helpers/uploadFileHelper"
import { FileReadOptions } from "fs/promises"
import {
   Order,
   OrderActivity,
   OrderActivityType,
   OrderStatus,
} from "../../../../helpers/types/order"
import { serverTimestamp } from "firebase/firestore"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../../../helpers/firestoreHelper"
import { EvergoCollections } from "../../../../helpers/types/firestore"
import { rejectOrder } from "../../../../helpers/user/orderHelper"

export default function RejectOrderModal({
   orderId,
   userId,
}: {
   orderId: string
   userId: string
}) {
   const [show, setShow] = useState(false)
   const [rejecting, setRejecting] = useState(false)
   const [uploadedFiles, setUploadedFiles] = useState([])
   const [uploadingFiles, setUploadingFiles] = useState(false)
   return (
      <>
         <Modal
            title={"Order Revision"}
            onCancel={() => {
               if (rejecting || uploadingFiles) return

               setShow(false)
            }}
            okText={"Reject"}
            okButtonProps={{
               htmlType: "submit",
               form: "deliverOrderForm",
               size: "large",
               danger: true,
               loading: rejecting,
               disabled: uploadingFiles,
            }}
            cancelButtonProps={{
               size: "large",
               disabled: rejecting || uploadingFiles,
               type: "primary",
            }}
            open={show}>
            <div className=''>
               <Form
                  id='deliverOrderForm'
                  onFinish={async (v) => {
                     try {
                        const newOrderActivity: OrderActivity = {
                           attachedFiles: uploadedFiles.map((f) => f.filename),
                           // @ts-ignore
                           createdAt: serverTimestamp(),
                           // @ts-ignore
                           updatedAt: serverTimestamp(),
                           description: v.description,
                           from: "user",
                           orderId,
                           userId,
                           type: OrderActivityType.orderRejected,
                        }

                        setRejecting(true)
                        const r = await rejectOrder({
                           description: v.description,
                           orderId,
                           files: uploadedFiles.map((f) => f.filename),
                        })

                        if (r.isSuccess) {
                           message.success(r.message)
                           setShow(false)
                        } else {
                           message.error(r.message)
                        }
                     } catch (E) {
                        message.error("Error delivering order")
                        console.log(E)
                     }

                     setRejecting(false)
                  }}
                  size='large'
                  layout='vertical'>
                  <div className='flex flex-col'>
                     <Form.Item
                        name={"description"}
                        label='Description'
                        rules={[
                           {
                              required: true,
                              message: "Please enter description",
                           },
                           {
                              min: 10,
                              message:
                                 "Description must be at least 10 characters",
                           },
                           {
                              max: 5000,
                              message:
                                 "Description must be at most 1000 characters",
                           },
                        ]}>
                        <TextArea rows={2} placeholder='Enter description' />
                     </Form.Item>
                     <label>Upload Files</label>
                     <ImageUploader
                        type={UploadType.orders}
                        userId={userId}
                        onChange={(f) => setUploadedFiles(f)}
                        onUploadStateChange={(u) => setUploadingFiles(u)}
                     />
                  </div>
               </Form>
            </div>
         </Modal>
         <ConfigProvider
            theme={{
               token: {
                  colorPrimary: "orange",
               },
            }}>
            <Button onClick={() => setShow(true)} size='large' type='primary'>
               Request Revision
            </Button>
         </ConfigProvider>
      </>
   )
}
