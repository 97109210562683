import {
   collection,
   doc,
   getFirestore,
   orderBy,
   query,
   where,
} from "firebase/firestore"
import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { useNavigate, useParams } from "react-router-dom"
import { EvergoCollections } from "../../../../helpers/types/firestore"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import {
   Order,
   OrderActivity,
   OrderActivityType,
} from "../../../../helpers/types/order"
import OrderCreationActivityView from "./activitesViews/OrderCreationActivityView"
import OrderContainer from "../../combined/OrderContainer"
import OrderDeliveryActivityView from "../../combined/order/OrderDeliveryActivityView"
import OrderRejectActivityView from "../../combined/order/OrderRejectActivityView"
import OrderAcceptActivityView from "../../combined/order/OrderAcceptActivityView"

export interface Time {
   days: number
   hours: number
   min: number
   sec: number
}

export default function OrderViewScreen() {
   const { orderId } = useParams()
   const navigate = useNavigate()

   if (!orderId) {
      navigate("/dashboard/orders")
   }
   const [orderSnapshot, orderLoading, orderError] = useDocument(
      doc(getFirestore(), EvergoCollections.orders, orderId)
   )
   const orderData = (orderSnapshot?.data?.() ?? {}) as Order

   const [ordersActivitySnapshot, ordersActivityLoading, ordersActivityError] =
      useCollection(
         query(
            collection(getFirestore(), EvergoCollections.ordersActivities),
            where("orderId", "==", orderId),
            where("userId", "==", getCurrentUserId()),
            orderBy("createdAt", "desc")
         )
      )

   const orderActivites = (ordersActivitySnapshot?.docs?.map?.((doc) =>
      doc.data()
   ) ?? []) as OrderActivity[]

   return (
      <OrderContainer
         activitiesLoading={ordersActivityLoading}
         orderId={orderLoading ? "" : orderData?.id ?? ""}
         orderRef={orderId}
         userType='user'
         key={orderId}>
         <>
            {orderActivites.map((a) => {
               if (a.type === OrderActivityType.orderCreated) {
                  return (
                     <OrderCreationActivityView
                        userType='user'
                        activity={a}
                        title={orderData.title}
                     />
                  )
               } else if (a.type === OrderActivityType.orderDelivered) {
                  return (
                     <OrderDeliveryActivityView activity={a} userType='user' />
                  )
               } else if (a.type === OrderActivityType.orderRejected) {
                  return (
                     <OrderRejectActivityView activity={a} userType='user' />
                  )
               } else if (a.type === OrderActivityType.orderAccepted) {
                  return (
                     <OrderAcceptActivityView activity={a} userType='user' />
                  )
               }
               return <></>
            })}
         </>
      </OrderContainer>
   )
}
