export const packagingStyle = [
   "REVERSE TUCK END - T100-1",
   "AERO TUCK - T100-2",
   "SAME PANEL TUCK - T100-3",
   "AUTO LOCK TUCK FRONT -A100-1",
   "AUTO LOCK TUCK BACK - A100-2",
   "123 BTM TUCK FRONT - N100-1",
   "123 BTM TUCK BACK - N100-2",
   "SEAL END - S100-1",
   "RTE HANGER - TH100-1",
   "123 HANGER - NH100-1",
   "AUTO LOCK HANGER - AH100-1",
   "DISPENSER 123 BTM - ND100-1",
   "DISPENSER AUTO BTM - AD100-1",
   "SLEEVE - SL100-1",
   "CLASSIC DOUBLE WALL TRAY - DWT100-1",
   "PREMIUM DOUBLE WALL TRAY",
   "4 CORNER TRAY - 4CT100-1",
   "4 CORNER TUCK LID - 4CTL100-1",
   "6 CORNER - 6C100-1",
   "TURN OVER LID - TOL100-1",
   "TURN OVER BUTTON LID - TOL100-2",
   "TURN OVER LOCK LID - TOL100-3",
   "123 BTM OPEN TOP - NO100-1",
   "AUTO BTM OPEN TOP - AO100-1",
   "DISPLAY WITH AUTO BTM - AY100-1",
   "DISPLAY WITH 123 BTM - NY100-1",
   "DIE CUT CARD - DC100-1",
   "FOLD OVER DIE CUT CARD - DCF100-1",
   "Others",
]

export const shippingOptions = {
   sp: "Store Pickup",
   qfe: "Quote Frieght Extra",
   tbd: "To Be Determined",
}

export const turnaroundTime = {
   1: "3-5 Days",
   2: "5-10 Days",
   3: "10-15 Days",
   4: "15-20 Days",
   5: "20-25 Days",
}

export const paperStock = [
   "32B White/White",
   "15PT Premium Stock",
   "18PT Premium Stock",
   "24PT Premium Stock",
   "18PT Recycled Stock",
   "24PT Recycled Stock",
   "18PT Kraft Stock",
   "24PT Kraft Stock",
   "32B Kraft 2 Side",
   "32B White/Kraft",
   "Others",
]

export const outsidePrintSpecifications = [
   "No Printing",
   "1 Colour Black",
   "4 Colour Process",
   "1 PMS Colour",
   "4 Colour + 1 PMS Colour",
   "4 Colour + 2 PMS Colour",
   "Others (Please specify in additional Information)",
]

export const outsideCoating = [
   "No Coating",
   "Semi Gloss Aqueous Coating",
   "Matte Varnish",
   "Spot UV (High Gloss)",
   "Overall UV (High Gloss)",
   "Gloss Lamination",
   "Matte Lamination",
]

export const insidePrintSpec = [
   "No Printing",
   "1 Colour Black",
   "1 Spot Colour",
   "4 Colour Process",
   "4 Colour + 1 Spot Colour",
   "4 Colour + 2 Spot Colour",
]

export const insideCoating = [
   "no coating",
   "Semi Gloss Aqueous Coating",
   "Matte Varnish",
   "Spot UV (High Gloss)",
   "Overall UV (High Gloss)",
   "Gloss Lamination",
   "Matte Lamination",
]

export const specialEffects = [
   "None",
   "Embossing",
   "Debossing",
   "Foil Stamping",
   "Window Patching",
]

export const quotationRequestIssueRejectionType = {
   incompletedinformation: "Incompleted Information",
   doNotOffer: "Do Not Offer",
   others: "Others",
}

export const canadaStates = [
   { label: "Alberta", value: "AB" },
   { label: "British Columbia", value: "BC" },
   { label: "Manitoba", value: "MB" },
   { label: "New Brunswick", value: "NB" },
   { label: "Newfoundland and Labrador", value: "NL" },
   { label: "Nova Scotia", value: "NS" },
   { label: "Ontario", value: "ON" },
   { label: "Prince Edward Island", value: "PE" },
   { label: "Quebec", value: "QC" },
   { label: "Saskatchewan", value: "SK" },
]
