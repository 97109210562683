import {
   CheckOutlined,
   SettingOutlined,
   ContainerOutlined,
   DollarOutlined,
} from "@ant-design/icons"
import { Button, Table } from "antd"
import {
   collection,
   getFirestore,
   orderBy,
   query,
   where,
} from "firebase/firestore"
import React from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { useNavigate } from "react-router-dom"
import { Quotation, QuoteStatus } from "../../../../helpers/interfaces"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import DateRenderer from "../combined/DateRenderer"
import QuoteStatusRenderer from "../combined/QuoteStatusRenderer"
import StatsCard from "./StatsCard"
import OrderStatusRenderer from "../combined/OrderStatusRenderer"
import { OrderStatus } from "../../../../helpers/types/order"

export default function HomeScreen() {
   const [orders, ordersLoading, ordersError] = useCollection(
      query(
         collection(getFirestore(), "orders"),
         where("userId", "==", getCurrentUserId())
      )
   )

   const [quotes, quotesLoading, quotesError] = useCollection(
      query(
         collection(getFirestore(), "quotes"),
         where("visible", "==", true),
         where("userId", "==", getCurrentUserId()),
         orderBy("createdAt", "desc")
      )
   )

   const navigate = useNavigate()

   const quotesColumns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Date Created",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text) => <DateRenderer date={text} />,
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (status: QuoteStatus) => (
            <QuoteStatusRenderer status={status} />
         ),
      },
      {
         title: "Action",
         key: "action",
         render: (text, record) => (
            <Button
               type='link'
               onClick={() => {
                  navigate("/dashboard/projects/quotations/" + record.id)
               }}>
               View
            </Button>
         ),
      },
   ]

   const ordersColumns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Date Created",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text) => <DateRenderer date={text} />,
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (status: OrderStatus) => (
            <OrderStatusRenderer status={status} />
         ),
      },
      {
         title: "Action",
         key: "action",
         render: (text, record) => (
            <Button
               type='link'
               onClick={() => {
                  navigate("/dashboard/projects/quotations/" + record.id)
               }}>
               View
            </Button>
         ),
      },
   ]

   return (
      <div className='flex flex-col w-full space-y-4 overflow-auto'>
         <div className='grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
            <StatsCard
               title='Pending Orders'
               loading={ordersLoading}
               iconColorClassName='text-orange-500 border-orange-500'
               value={(orders?.docs?.length ?? 0) + ""}
               icon={<SettingOutlined />}
            />
            <StatsCard
               title='Completed Orders'
               loading={ordersLoading}
               iconColorClassName='text-green-500 border-green-500'
               value={(orders?.docs?.length ?? 0) + ""}
               icon={<CheckOutlined />}
            />
            <StatsCard
               title='Accepted Quotes'
               loading={quotesLoading}
               iconColorClassName='text-blue-500 border-blue-500'
               value={
                  (quotes?.docs?.filter((q) => {
                     const quote = q.data() as Quotation
                     return quote.status === QuoteStatus.accepted
                  }).length ?? 0) + ""
               }
               icon={<DollarOutlined />}
            />
            <StatsCard
               title='Pending Quotes'
               loading={quotesLoading}
               iconColorClassName='text-yellow-500 border-yellow-500'
               value={
                  (quotes?.docs?.filter((q) => {
                     const quote = q.data() as Quotation
                     return quote.status === QuoteStatus.submitted
                  }).length ?? 0) + ""
               }
               icon={<ContainerOutlined />}
            />
         </div>
         <div className='flex flex-col space-y-3'>
            <h1 className='font-semibold text-lg'>Quotations</h1>

            <Table
               loading={quotesLoading}
               columns={quotesColumns}
               pagination={{
                  pageSize: 5,
               }}
               dataSource={
                  quotes?.docs?.map((d) => ({
                     ...d.data(),
                     id: d.id,
                  })) ?? []
               }
            />
         </div>
         <div className='flex flex-col space-y-3'>
            <h1 className='font-semibold text-lg'>Orders</h1>

            <Table
               pagination={{
                  pageSize: 5,
               }}
               loading={ordersLoading}
               columns={ordersColumns}
               dataSource={
                  orders?.docs?.map((d) => ({
                     ...d.data(),
                     id: d.id,
                  })) ?? []
               }
            />
         </div>
      </div>
   )
}
