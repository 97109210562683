import React from "react"

export default function Title({
   text,
   className = "",
}: {
   text: string

   className?: string
}) {
   return (
      <h1 className={"m-0 font-semibold text-lg " + (className ?? "")}>
         {text}
      </h1>
   )
}
