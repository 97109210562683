import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import React from "react"

export default function Loading({
   children,
   loading = true,
   className = "",
   ...props
}: {
   children?: React.ReactNode
   loading?: boolean
   className?: string
}) {
   if (children) {
      return (
         <Spin
            spinning={loading}
            {...props}
            className={`${className ?? ""}`}
            size='large'
            indicator={<LoadingOutlined />}>
            {children}
         </Spin>
      )
   }
   return (
      <div className='h-full w-full flex justify-center items-center'>
         <Spin size='large' {...props} indicator={<LoadingOutlined />} />
      </div>
   )
}
