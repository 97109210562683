import { Quotation, QuoteStatus } from "../../../../helpers/interfaces"
import DateRenderer from "../../user/combined/DateRenderer"
import UserOverview from "../combined/UserOverview"
import {
   Breadcrumb,
   Button,
   Form,
   Input,
   Popconfirm,
   Space,
   Table,
   message,
} from "antd"
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem"
import {
   collection,
   getFirestore,
   orderBy,
   query,
   where,
} from "firebase/firestore"
import { useCollection } from "react-firebase-hooks/firestore"
import Title from "../../combined/Title"
import QuoteStatusRenderer from "../../user/combined/QuoteStatusRenderer"
import { DeleteFilled, EyeFilled } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { deleteCustomDocument } from "../../../../helpers/firestoreHelper"
import { useEffect, useState } from "react"
import UserFormField from "../combined/UserFormField"
import QuoteStatusFormField from "../../combined/QuoteStatusFormField"

type QuotesFilter = {
   userId: string
   title: string
   status: QuoteStatus
}

export default function AdminQuotationsScreen() {
   const navigate = useNavigate()
   const [form] = Form.useForm()

   const [quotesFilter, setQuotesFilter] = useState<QuotesFilter>({
      status: QuoteStatus.submitted,
      title: "",
      userId: "",
   })

   const [quotations, quotationsLoading, quotationsError] = useCollection(
      query(
         collection(getFirestore(), "quotes"),
         where("status", "==", quotesFilter.status),
         orderBy("createdAt", "desc")
      )
   )

   console.log(quotationsError)
   const quotationsData =
      quotations?.docs.map((doc) => {
         return {
            id: doc.id,
            ...doc.data(),
         } as Quotation
      }) ?? []
   const columns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Date Created",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text: any, record: Quotation) => {
            return <DateRenderer date={record.createdAt} />
         },
      },
      {
         title: "Submitted By",
         dataIndex: "userId",
         key: "user",
         render: (userId: string) => <UserOverview userId={userId} />,
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (status: string) => (
            <QuoteStatusRenderer status={status as QuoteStatus} />
         ),
      },
      {
         title: "Actions",
         key: "actions",
         render: (_: any, record: Quotation) => {
            return (
               <Space>
                  <Button
                     onClick={() => {
                        navigate(
                           `/admin/dashboard/quotations/view/${record.id}`
                        )
                     }}
                     type='primary'
                     icon={<EyeFilled />}
                  />
                  <Popconfirm
                     onConfirm={async () => {
                        try {
                           await deleteCustomDocument("quotes", record.id)
                           message.success("Quotation deleted successfully")
                        } catch (e) {
                           message.error("Error deleting quotation")
                        }
                     }}
                     title="Are you sure? This can't be undone.">
                     <Button danger type='primary' icon={<DeleteFilled />} />
                  </Popconfirm>
               </Space>
            )
         },
      },
   ]

   useEffect(() => {
      if (form) {
         form.setFieldValue("quoteStatus", QuoteStatus.submitted)
      }
   }, [form])
   return (
      <div className='flex flex-col space-y-2 flex-1 overflow-auto'>
         <div>
            <Breadcrumb>
               <BreadcrumbItem>Projects</BreadcrumbItem>
               <BreadcrumbItem>New Quotations</BreadcrumbItem>
            </Breadcrumb>
         </div>
         <Title text='Quotations Filters' />

         <div className='flex flex-col'>
            <Form
               form={form}
               onFinish={(v) => {
                  setQuotesFilter({
                     status: v.quoteStatus,
                     title: v.title,
                     userId: v.userId,
                  })
               }}
               size='large'>
               <div className='flex flex-row space-x-2'>
                  <Form.Item name={"title"}>
                     <Input placeholder='Search by title' />
                  </Form.Item>
                  <UserFormField />
                  <QuoteStatusFormField />
                  <Form.Item>
                     <Button htmlType='submit' type='primary'>
                        Search
                     </Button>
                  </Form.Item>
                  <Form.Item>
                     <Button
                        onClick={() => {
                           setQuotesFilter({
                              status: QuoteStatus.submitted,
                              title: "",
                              userId: "",
                           })

                           form.setFieldValue(
                              "quoteStatus",
                              QuoteStatus.submitted
                           )

                           form.setFieldValue("title", "")
                           form.setFieldValue("userId", null)
                        }}
                        danger>
                        Clear
                     </Button>
                  </Form.Item>
               </div>
            </Form>
         </div>

         <Title text='New Quotations List' />
         <div>
            <Table
               rowKey={(record) => record.id}
               dataSource={quotationsData.filter((q) => {
                  if (quotesFilter.title) {
                     return q.title
                        .toLowerCase()
                        .includes(quotesFilter.title.toLowerCase())
                  }

                  if (quotesFilter.userId && quotesFilter.userId.length > 0) {
                     return q.userId === quotesFilter.userId
                  }

                  if (quotesFilter.status) {
                     return q.status === quotesFilter.status
                  }

                  return true
               })}
               columns={columns}
               loading={quotationsLoading}
            />
         </div>
      </div>
   )
}
