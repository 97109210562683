import {
   Breadcrumb,
   Button,
   Input,
   Popconfirm,
   Space,
   Table,
   message,
} from "antd"
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem"
import { collection, getFirestore, query, where } from "firebase/firestore"
import React, { useState } from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import Title from "../../combined/Title"
import { DeleteFilled } from "@ant-design/icons"
import {
   deleteUser,
   toggleUserStatus,
} from "../../../../helpers/admin/adminsHelper"
import { EvergoUser } from "../../../../helpers/interfaces"

export default function AdminUsersScreen() {
   const [usersSnapshot, usersLoading, usersError] = useCollection(
      query(collection(getFirestore(), "users"), where("role", "==", "user"))
   )

   // console.log(usersSnapshot?.docs.map(doc => doc.data()))

   console.log(usersSnapshot?.docs.map((doc) => doc.data()))

   const columns = [
      {
         title: "Name",
         dataIndex: "fullname",
         key: "name",
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Staus",
         dataIndex: "isEnabled",
         key: "isEnabled",
         render: (isEnabled: boolean) => {
            return isEnabled ? "Enabled" : "Disabled"
         },
      },
      {
         title: "Action",
         key: "action",
         render: (user) => {
            return (
               <Space>
                  <Popconfirm
                     title={
                        "Are you sure you want to " +
                        (user.isEnabled ? "disable" : "enable") +
                        " this user?"
                     }
                     onConfirm={async () => {
                        console.log(user)
                        const r = await toggleUserStatus(user.id)
                        if (r.isSuccess) {
                           message.success(r.message)
                        } else {
                           message.error(r.message)
                        }
                     }}>
                     <Button danger={user.isEnabled} type={"primary"}>
                        {user.isEnabled ? "Disable" : "Enable"}
                     </Button>
                  </Popconfirm>
                  <Popconfirm
                     title='Are you sure to delete this user?'
                     onConfirm={async () => {
                        const r = await deleteUser(user.id)
                        if (r.isSuccess) {
                           message.success(r.message)
                        } else {
                           message.error(r.message)
                        }
                     }}>
                     <Button danger icon={<DeleteFilled />} />
                  </Popconfirm>
               </Space>
            )
         },
      },
   ]

   const users = usersSnapshot?.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
   })) as EvergoUser[]
   const [searchText, setSearchText] = useState("")
   return (
      <div className='flex flex-col space-y-2 flex-1 overflow-auto'>
         <div>
            <Breadcrumb>
               <BreadcrumbItem>Admin</BreadcrumbItem>
               <BreadcrumbItem>Users</BreadcrumbItem>
            </Breadcrumb>
         </div>
         <Title text='Users' />
         <Input.Search
            allowClear
            placeholder='Search User'
            onSearch={(s) => {
               setSearchText(s)
            }}
         />

         <div className='flex flex-1 flex-col'>
            <Table
               columns={columns}
               dataSource={
                  users?.filter((u) => {
                     return (
                        u.fullname
                           .toLowerCase()
                           .includes(searchText.toLowerCase()) ||
                        u.email.toLowerCase().includes(searchText.toLowerCase())
                     )
                  }) ?? []
               }
               loading={usersLoading}
               rowKey='id'
            />
         </div>
      </div>
   )
}
