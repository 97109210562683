import {
   QuotationRequest,
   QuotationRequestType,
   QuoteRequestStatus,
   UserView,
} from "../../helpers/interfaces"
import QuoteRequestRejectionView from "../screens/combined/QuoteRequestRejectionView"
import QuoteRequestAwaitingView from "../screens/combined/QuoteRequestAwaitingView"
import QuoteRequestAcceptanceView from "../screens/combined/QuoteRequestAcceptanceView"

export default function ShortQuotationRequestView({
   quotationRequest: qr,
   view,
}: {
   quotationRequest: QuotationRequest
   view: UserView
}) {
   if (qr.type === QuotationRequestType.submission) {
      return <QuoteRequestAwaitingView quotationRequest={qr} view={view} />
   }

   if (
      qr.status === QuoteRequestStatus.rejected &&
      qr.type === QuotationRequestType.modification
   )
      return <QuoteRequestRejectionView view={view} quoteRequest={qr} />

   if (
      qr.status === QuoteRequestStatus.accepted &&
      qr.type === QuotationRequestType.modification
   ) {
      return <QuoteRequestAcceptanceView view={view} quoteRequest={qr} />
   }
}
