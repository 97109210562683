import { doc, getFirestore } from "firebase/firestore"
import React from "react"
import { useDocument } from "react-firebase-hooks/firestore"
import Loading from "../../../Loading"
import { User } from "../../../../helpers/interfaces"

export default function UserOverview({ userId }: { userId: string }) {
   const [user, loading, error] = useDocument(
      doc(getFirestore(), "users", userId)
   )

   if (loading) {
      // @ts-ignore
      return <Loading size='small' />
   }

   if (error) {
      console.error(error)
      return <div>Error Loading User</div>
   }

   if (!user.exists()) {
      return <div className='text-red-200'>User Not Found</div>
   }

   const userData = user?.data() as User

   return <div>{userData?.fullname}</div>
}
