import React from "react"
import { QuotationRequest } from "../../../../helpers/interfaces"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { doc, getFirestore } from "firebase/firestore"
import AddUpdateQuotation from "./AddUpdateQuotation"
import { GrUpdate } from "react-icons/gr"
import { SyncOutlined } from "@ant-design/icons"

interface UpdateQoutationRequestProps {
   onUpdate: (qr: QuotationRequest) => Promise<boolean>
   quotationRequestId: string
}

export default function UpdateQuotationRequest({
   onUpdate,
   quotationRequestId,
}: UpdateQoutationRequestProps) {
   const [qrs, loading, error] = useDocumentData(
      doc(getFirestore(), "quote-requests", quotationRequestId)
   )

   return (
      <>
         <AddUpdateQuotation
            buttonType='primary'
            buttonProps={{
               loading: loading,
               icon: <SyncOutlined />,
            }}
            showInitial={false}
            type='update'
            onUpdate={async (q) => {
               return await onUpdate(q)
            }}
            quotation={(qrs ?? {}) as QuotationRequest}
         />
      </>
   )
}
