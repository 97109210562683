import { Form, Select } from "antd"
import React from "react"
import { ordersOption } from "../../../helpers/defaultData"

export default function OrderStatusFormField() {
   return (
      <Form.Item
         style={{
            width: "200px",
         }}
         name={"orderStatus"}>
         <Select allowClear placeholder='Select order' options={ordersOption} />
      </Form.Item>
   )
}
