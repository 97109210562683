import {
   and,
   collection,
   getCountFromServer,
   getFirestore,
   limit,
   or,
   orderBy,
   query,
   where,
} from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { Link, useNavigate } from "react-router-dom"
import DateRenderer from "../../user/combined/DateRenderer"
import { Quotation, QuoteStatus } from "../../../../helpers/interfaces"
import QuoteStatusRenderer from "../../user/combined/QuoteStatusRenderer"
import { Button, Table } from "antd"
import StatsCard from "../../user/home/StatsCard"
import {
   CheckOutlined,
   ContainerOutlined,
   DollarOutlined,
   SettingOutlined,
} from "@ant-design/icons"
import { OrderStatus } from "../../../../helpers/types/order"
import Loading from "../../../Loading"
import OrderStatusRenderer from "../../user/combined/OrderStatusRenderer"

export default function AdminHomeScreen() {
   const [orders, ordersLoading, ordersError] = useCollection(
      query(
         collection(getFirestore(), "orders"),
         orderBy("updatedAt", "desc"),
         limit(5)
      )
   )

   const [quotes, quotesLoading, quotesError] = useCollection(
      query(
         collection(getFirestore(), "quotes"),
         where("status", "!=", QuoteStatus.orderCreated),
         limit(5)
      )
   )

   const quotesColumns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Date Created",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text) => <DateRenderer date={text} />,
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (status: QuoteStatus) => (
            <QuoteStatusRenderer status={status} />
         ),
      },
      {
         title: "Action",
         key: "action",
         render: (text, record) => (
            <Link to={"/admin/dashboard/quotations/view/" + record.id}>
               <Button type='link'>View</Button>
            </Link>
         ),
      },
   ]

   const ordersColumns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Date Created",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text) => <DateRenderer date={text} />,
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (status: OrderStatus) => (
            <OrderStatusRenderer status={status} />
         ),
      },
      {
         title: "Action",
         key: "action",
         render: (text, record) => (
            <Link to={"/admin/dashboard/orders/" + record.id}>
               <Button type='link'>View</Button>
            </Link>
         ),
      },
   ]

   const [counts, setCounts] = useState<{
      pendingOrder
      completedOrder
      pendingQuote
      acceptedQuote
   }>({
      acceptedQuote: 0,
      completedOrder: 0,
      pendingOrder: 0,
      pendingQuote: 0,
   })

   const [loadingCounts, setLoadingCounts] = useState(true)

   useEffect(() => {
      const loadCounts = async () => {
         const counts = {
            acceptedQuote: 0,
            completedOrder: 0,
            pendingOrder: 0,
            pendingQuote: 0,
         }

         const completedOrderCounts = await getCountFromServer(
            query(
               collection(getFirestore(), "orders"),
               where("status", "==", OrderStatus.approved)
            )
         )

         const pendingOrderCounts = await getCountFromServer(
            query(
               collection(getFirestore(), "orders"),
               and(
                  or(
                     where("status", "==", OrderStatus.created),
                     where("status", "==", OrderStatus.customerReviewPending),
                     where("status", "==", OrderStatus.submitted)
                  )
               )
            )
         )

         const acceptedQuotesCount = await getCountFromServer(
            query(
               collection(getFirestore(), "quotes"),
               and(
                  where("visible", "==", true),
                  or(
                     where("status", "==", QuoteStatus.accepted),
                     where("status", "==", QuoteStatus.orderCreated)
                  )
               )
            )
         )

         const pendingQuotes = await getCountFromServer(
            query(
               collection(getFirestore(), "quotes"),
               and(
                  where("visible", "==", true),
                  or(
                     where("status", "==", QuoteStatus.modificationRequested),
                     where("status", "==", QuoteStatus.submitted)
                  )
               )
            )
         )

         counts.acceptedQuote = acceptedQuotesCount.data().count
         counts.completedOrder = completedOrderCounts.data().count
         counts.pendingOrder = pendingOrderCounts.data().count
         counts.pendingQuote = pendingQuotes.data().count

         setCounts(counts)
         setLoadingCounts(false)
      }

      loadCounts()
   }, [])

   return (
      <div className='flex flex-col w-full space-y-4 overflow-auto'>
         <Loading loading={loadingCounts}>
            <div className='grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
               <StatsCard
                  title='Pending Orders'
                  value={counts.pendingOrder + ""}
                  iconColorClassName='text-yellow-500 border-yellow-500'
                  icon={<SettingOutlined />}
               />
               <StatsCard
                  title='Completed Orders'
                  iconColorClassName='text-green-500 border-green-500'
                  value={counts.completedOrder + ""}
                  icon={<CheckOutlined />}
               />
               <StatsCard
                  title='Accepted Quotes'
                  iconColorClassName='text-blue-500 border-blue-500'
                  value={counts.acceptedQuote + ""}
                  icon={<DollarOutlined />}
               />
               <StatsCard
                  title='Pending Quotes'
                  iconColorClassName='text-orange-500 border-orange-500'
                  value={counts.pendingQuote + ""}
                  icon={<ContainerOutlined />}
               />
            </div>
         </Loading>
         <div className='flex flex-col space-y-3'>
            <h1 className='font-semibold text-lg'>Quotations</h1>

            <Table
               loading={quotesLoading}
               columns={quotesColumns}
               pagination={{
                  pageSize: 5,
               }}
               dataSource={
                  quotes?.docs?.map((d) => ({
                     ...d.data(),
                     id: d.id,
                  })) ?? []
               }
            />
         </div>
         <div className='flex flex-col space-y-3'>
            <h1 className='font-semibold text-lg'>Orders</h1>

            <Table
               pagination={{
                  pageSize: 5,
               }}
               loading={ordersLoading}
               columns={ordersColumns}
               dataSource={
                  orders?.docs?.map((d) => ({
                     ...d.data(),
                     id: d.id,
                  })) ?? []
               }
            />
         </div>
      </div>
   )
}
