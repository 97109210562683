import { getFunctions, httpsCallable } from "firebase/functions"
import { ServerResponse, errorResponse } from "../interfaces"
import { app, defaultRegion } from "../firebase"
import { StartOrderData } from "../types/order"

export const startOrder = async (
   data: StartOrderData
): Promise<ServerResponse> => {
   try {
      const response = await httpsCallable(
         getFunctions(app, defaultRegion),
         "startOrder"
      )(data)

      if (response && response.data) {
         // @ts-ignore
         return response.data.message as ServerResponse
      }

      return errorResponse("Error starting order.")
   } catch (e) {
      console.log(e)
      return errorResponse("Error starting order.")
   }
}

export const acceptOrder = async (data: {
   description: string
   orderId: string
}): Promise<ServerResponse> => {
   try {
      const response = await httpsCallable(
         getFunctions(app, defaultRegion),
         "acceptOrder"
      )(data)

      if (response && response.data) {
         // @ts-ignore
         return response.data.message as ServerResponse
      }

      return errorResponse("Error starting order.")
   } catch (e) {
      console.log(e)
      return errorResponse("Error starting order.")
   }
}

export const rejectOrder = async (data: {
   description: string
   orderId: string
   files: string[]
}): Promise<ServerResponse> => {
   try {
      const response = await httpsCallable(
         getFunctions(app, defaultRegion),
         "rejectOrder"
      )(data)

      if (response && response.data) {
         // @ts-ignore
         return response.data.message as ServerResponse
      }

      return errorResponse("Error starting order.")
   } catch (e) {
      console.log(e)
      return errorResponse("Error starting order.")
   }
}
