import { getAuth } from "firebase/auth"

export const getCurrentUserId = (): string => {
   return getAuth()?.currentUser?.uid
}

export const isAdmin = async (): Promise<boolean> => {
   const response = await getAuth().currentUser.getIdTokenResult()

   return response.claims?.admin
}
