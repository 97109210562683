import { Tag } from "antd"
import React from "react"
import { QuoteRequestStatus } from "../../../../helpers/interfaces"

export default function QuotationRequestStatusRenderer({
   status,
}: {
   status: QuoteRequestStatus
}) {
   return (
      <Tag
         color={
            status === QuoteRequestStatus.accepted
               ? "green"
               : status === QuoteRequestStatus.awaiting
               ? "orange"
               : "red"
         }
         className='uppercase'>
         {status}
      </Tag>
   )
}
