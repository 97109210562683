import { Button, Form, Modal, message } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { useState } from "react"
import ImageUploader from "../../combined/ImageUploader"
import { UploadType } from "../../../../helpers/uploadFileHelper"
import { FileReadOptions } from "fs/promises"
import {
   Order,
   OrderActivity,
   OrderActivityType,
   OrderStatus,
} from "../../../../helpers/types/order"
import { serverTimestamp } from "firebase/firestore"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../../../helpers/firestoreHelper"
import { EvergoCollections } from "../../../../helpers/types/firestore"
import { acceptOrder } from "../../../../helpers/user/orderHelper"

export default function AcceptOrderModal({
   orderId,
   userId,
}: {
   orderId: string
   userId: string
}) {
   const [show, setShow] = useState(false)
   const [accepting, setAccepting] = useState(false)
   const [form] = Form.useForm()
   return (
      <>
         <Modal
            title={"Accept Order Delivery"}
            onCancel={() => {
               if (accepting) return

               setShow(false)
            }}
            okText={"Accept"}
            okButtonProps={{
               htmlType: "submit",
               form: "acceptOrderForm",
               size: "large",
               loading: accepting,
            }}
            cancelButtonProps={{
               size: "large",
               disabled: accepting,
            }}
            open={show}>
            <div className=''>
               <Form
                  form={form}
                  id='acceptOrderForm'
                  onFinish={async (v) => {
                     try {
                        setAccepting(true)
                        const r = await acceptOrder({
                           description: v.description,
                           orderId,
                        })

                        if (r.isSuccess) {
                           message.success("Order accepted successfully")
                           form.resetFields()
                           setShow(false)
                        } else {
                           message.error("Error accepting order")
                        }
                     } catch (E) {
                        message.error("Error accepting order")
                        console.log(E)
                     }

                     setAccepting(false)
                  }}
                  size='large'
                  layout='vertical'>
                  <div className='flex flex-col'>
                     <Form.Item
                        name={"description"}
                        label='Comments'
                        rules={[
                           {
                              max: 5000,
                              message:
                                 "Description must be at most 1000 characters",
                           },
                        ]}>
                        <TextArea rows={2} placeholder='Enter comments' />
                     </Form.Item>
                  </div>
               </Form>
            </div>
         </Modal>
         <Button
            className='flex-1'
            onClick={() => setShow(true)}
            size='large'
            type='primary'>
            {"Complete Order"}
         </Button>
      </>
   )
}
