import React, { ReactNode } from "react"

export default function Section({
   className = "",
   title,
   children,
}: {
   className?: string
   title: string
   children: ReactNode
}) {
   return (
      <div
         className={
            "flex flex-col drop-shadow-lg  bg-[#F9F9F9] border border-solid border-opacity-50 border-gray-300 rounded-md " +
            className
         }>
         <div className='bg-white text-base p-2 rounded-md'>{title}</div>
         {children}
      </div>
   )
}
