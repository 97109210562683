import { Button, Tooltip, message } from "antd"
import {
   collection,
   doc,
   getFirestore,
   orderBy,
   query,
   where,
} from "firebase/firestore"
import React from "react"
import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { Link, Navigate, useParams } from "react-router-dom"
import ShortQuotationRequestView from "../../../viewquotation/ShortQuotationRequestView"
import {
   Quotation,
   QuotationRequest,
   QuoteStatus,
   UserView,
} from "../../../../helpers/interfaces"
import Loading from "../../../Loading"
import RejectQuotation from "./RejectQuotation"
import AcceptQuotation from "./AcceptQuotation"

export default function AdminViewQuotationScreen() {
   const { id } = useParams()

   const [quotationRequests, quotationsLoading, quotationsError] =
      useCollection(
         query(
            collection(getFirestore(), "quote-requests"),
            where("quoteId", "==", id),
            orderBy("createdAt", "asc")
         )
      )

   const [quotation, quotationLoading, quotationError] = useDocument(
      doc(getFirestore(), "quotes", id)
   )

   if (!id) {
      message.error("Invalid quotation id")
      return <Navigate to={"/admin/dashboard/new-quotations"} />
   }

   const quotationsRequestsData: QuotationRequest[] =
      quotationRequests?.docs.map((doc) => {
         const data = doc.data()
         return {
            ...data,
            id: doc.id,
         } as QuotationRequest
      }) ?? []

   const quoteData: Quotation = {
      ...quotation?.data(),
      id: quotation?.id,
   } as Quotation

   return (
      <div className='flex flex-col flex-1 my-2 overflow-auto rounded-lg '>
         <Loading loading={quotationsLoading}>
            <div className='flex flex-col space-y-4'>
               <div className='flex flex-col space-y-2'>
                  {quotationsRequestsData?.map((qr) => (
                     <ShortQuotationRequestView
                        view={UserView.admin}
                        quotationRequest={qr}
                     />
                  ))}
               </div>

               <div className='flex flex-col'>
                  {quoteData.status === QuoteStatus.submitted && (
                     <div className='flex flex-row justify-center space-x-2'>
                        <RejectQuotation quote={quoteData} />
                        <AcceptQuotation quote={quoteData} />
                     </div>
                  )}
                  {quoteData.status === QuoteStatus.orderCreated && (
                     <div className='items-center flex flex-row text-green-500 rounded-lg mb-5 justify-center shadow-lg border border-green-700 p-2 font-semibold'>
                        <h1>User placed the order.</h1>
                        <Link
                           to={"/admin/dashboard/orders/" + quoteData.orderId}>
                           <Button type='link' size='small'>
                              View
                           </Button>
                        </Link>
                     </div>
                  )}
                  {(quoteData.status === QuoteStatus.modificationRequested ||
                     quoteData.status === QuoteStatus.accepted) && (
                     <div className='flex mb-5 flex-row text-white rounded-lg justify-center bg-primary p-2 font-semibold'>
                        Waiting for user's response
                     </div>
                  )}
               </div>
            </div>
         </Loading>
      </div>
   )
}
