import { Button, Image, Upload, UploadFile, message } from "antd"
import {
   UploadTask,
   getStorage,
   ref,
   uploadBytesResumable,
} from "firebase/storage"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import {
   UploadType,
   getNewFileName,
   getNewFileNameWithoutUserId,
} from "../../../helpers/uploadFileHelper"

interface FileOptions {
   uploading: boolean
   uploaded: boolean
   filename: string
   uploadTask: UploadTask
   uid: string
}

export enum AllowFileType {
   image = ".png,.jpg,.jpeg",
   all = "*",
}

export interface UploadedFile {
   uid: string
   filename: string
}

export default function ImageUploader({
   onChange,
   onUploadStateChange,
   userId,
   type,
   onFileListChange,
   fileList,
   allowed = AllowFileType.all,
   onFileAdd,
   onFileRemove,
   maximumFilesAllowed = 5,
   customButton,
}: {
   onChange?: (files: FileOptions[]) => void
   onUploadStateChange?: (uploading: boolean) => void
   userId: string
   type: UploadType
   onFileListChange?: (files: UploadFile[]) => void
   fileList?: UploadFile[]
   onFileAdd?: (file: UploadedFile) => void
   onFileRemove?: (uid: string) => void
   allowed?: AllowFileType
   maximumFilesAllowed?: number
   customButton?: ReactNode
}) {
   const [uploadedFiles, setUploadedFilesx] = useState<FileOptions[]>([])

   useEffect(() => {
      onChange?.(uploadedFiles)
   }, [uploadedFiles])

   const cancelFileUpload = async (uid) => {
      try {
         //get uploaded file
         const file = newFileList.current.find((f) => f.uid === uid)

         if (file) {
            file.uploadTask?.cancel()
            newFileList.current = newFileList.current.filter(
               (f) => f.uid !== uid
            )
            //delete from state
            setUploadedFilesx(newFileList.current)
         }
      } catch (e) {
         message.error("Error cancelling file upload")
      }
   }
   let newFileList = useRef([])
   const customUpload = async (d) => {
      let fileName = getNewFileName(d.file.name, type, userId)

      const { file, onError, onSuccess, onProgress } = d
      const storage = getStorage()
      const metadata = {
         contentType: file.type,
      }
      const storageRef = ref(storage, fileName)

      try {
         onUploadStateChange?.(true)
         const uploadTask: UploadTask = uploadBytesResumable(
            storageRef,
            file,
            metadata
         )
         const nf = {
            filename: getNewFileNameWithoutUserId(fileName),
            uid: d.file.uid,
            uploaded: false,
            uploading: true,
            uploadTask: uploadTask,
         }
         newFileList.current = [...newFileList.current, nf]
         setUploadedFilesx(newFileList.current)
         uploadTask.on("state_changed", (ss) => {
            const progress = (ss.bytesTransferred / ss.totalBytes) * 100
            onProgress({
               percent: progress,
            })
            if (progress === 100) {
               onUploadStateChange?.(false)
               newFileList.current = newFileList.current.map((f) => {
                  if (f.uid === d.file.uid) {
                     return {
                        ...f,
                        uploading: false,
                        uploaded: true,
                        uploadTask: null,
                     }
                  }

                  return f
               })
               onFileAdd?.({
                  uid: d.file.uid,
                  filename: getNewFileNameWithoutUserId(fileName),
               })

               setUploadedFilesx(newFileList.current)
               onSuccess(null)
            }

            switch (ss.state) {
               case "error":
                  newFileList.current = newFileList.current.filter(
                     (f) => f.uid !== d.file.uid
                  )
                  setUploadedFilesx(newFileList.current)
                  onFileRemove?.(d.file.uid)
                  onError("Error")
                  onUploadStateChange?.(false)
                  break
            }
         })
      } catch (e) {
         newFileList.current = newFileList.current.filter(
            (f) => f.uid !== d.file.uid
         )
         setUploadedFilesx(newFileList.current)
         onError(e)
         onUploadStateChange?.(false)
      }
   }
   const [showImageUrl, setShowImageUrl] = useState(null)
   return (
      <>
         {showImageUrl && (
            <Image
               style={{
                  display: "none",
               }}
               className='w-0'
               preview={{
                  visible: true,
                  onVisibleChange: (v) => {
                     setShowImageUrl(null)
                  },
               }}
               src={showImageUrl}
            />
         )}
         <Upload
            listType={
               type === UploadType.profilePicture
                  ? "text"
                  : allowed === AllowFileType.image
                  ? "picture-card"
                  : "text"
            }
            accept={allowed.toString()}
            disabled={uploadedFiles.some((a) => a.uploading)}
            beforeUpload={(file) => {
               //if file type if ogg, wav, or mp3
               const isFileAllowed =
                  allowed === AllowFileType.all
                     ? true
                     : allowed === AllowFileType.image
                     ? file.type === "image/png" ||
                       file.type === "image/jpeg" ||
                       file.type === "image/jpg"
                     : false
               if (!isFileAllowed) {
                  message.error("File type not allowed.")
               }

               // You can remove this validation if you want
               const isLt5M = file.size / 1024 / 1024 < 5
               if (!isLt5M) {
                  message.error("Image must smaller than 5MB!")
               }
               return isFileAllowed && isLt5M
            }}
            onRemove={(f) => {
               cancelFileUpload(f.uid)
               onFileRemove?.(f.uid)
            }}
            onPreview={(a) => {
               const reader = new FileReader()

               reader.onload = function (event) {
                  const base64Data = event.target.result
                  setShowImageUrl(base64Data as string)
               }

               reader.readAsDataURL(a.originFileObj)
            }}
            {...(fileList
               ? {
                    fileList,
                 }
               : {})}
            onChange={(a) => {
               onFileListChange?.(a.fileList)
            }}
            customRequest={customUpload}
            maxCount={maximumFilesAllowed}>
            {uploadedFiles.length < maximumFilesAllowed &&
               (customButton ?? allowed === AllowFileType.all ? (
                  <Button>+ Upload</Button>
               ) : (
                  "+ Upload"
               ))}
         </Upload>
      </>
   )
}
