import React, { ReactNode } from "react"

export default function ActivityRootView({
   children,
   header,
   headerColorClass,
}: {
   header: string
   headerColorClass: string
   children: ReactNode
}) {
   return (
      <div className='flex flex-col bg-white rounded-lg border border-solid border-gray-300 overflow-hidden'>
         <div className={"p-1 " + headerColorClass}>
            <h1>{header}</h1>
         </div>
         <div className='flex flex-col p-4 '>{children}</div>
      </div>
   )
}
