import { getCurrentUserId } from "./userAuthHelpers"

export const getNewFileNameWithoutUserId = (fileName: string) => {
   return fileName.split("/").slice(1).join("/")
}

export const getNewFileName = (
   orginalName: string,
   type: UploadType,
   userId?: string
) => {
   var fileExtension = orginalName.split(".").pop()

   //@ts-ignore
   let newFileName = new Date() / 1 + "." + fileExtension

   return (userId ?? getCurrentUserId()) + "/" + type + "/" + newFileName
}

export enum UploadType {
   bankPaymentProof = "bank-payment-proofs",
   quotes = "quotes",
   orders = "orders",
   profilePicture = "profile-picture",
}
