import { app, defaultRegion } from "../firebase"
import { getFunctions, httpsCallable } from "firebase/functions"
import { IAddUpdateAdmin, ServerResponse, errorResponse } from "../interfaces"

export const addNewAdmin = async (
   newAdmin: IAddUpdateAdmin
): Promise<ServerResponse> => {
   try {
      const response = await httpsCallable(
         getFunctions(app, defaultRegion),
         "addNewAdmin"
      )(newAdmin)

      if (response && response.data) {
         // @ts-ignore
         return response.data.message as ServerResponse
      }

      return errorResponse("Error adding new admin.")
   } catch (e) {
      return errorResponse("Error adding new admin.")
   }
}

//generate function for remove admin, with endpoint of removeAdmin and data as uid from function parameter
export const removeAdmin = async (uid: string): Promise<ServerResponse> => {
   try {
      const response = await httpsCallable(
         getFunctions(app, defaultRegion),
         "removeAdmin"
      )({ uid })

      if (response && response.data) {
         // @ts-ignore
         return response.data.message as ServerResponse
      }

      return errorResponse("Error removing admin.")
   } catch (e) {
      return errorResponse("Error removing admin.")
   }
}

//generate function for togelling disable admin, with endpoint toggleActivateAdmin, and data as uid from function parameter
export const toggleActivateAdmin = async (
   uid: string
): Promise<ServerResponse> => {
   try {
      const response = await httpsCallable(
         getFunctions(app, defaultRegion),
         "toggleActivateAdmin"
      )({ uid })

      if (response && response.data) {
         // @ts-ignore
         return response.data.message as ServerResponse
      }

      return errorResponse("Error toggling admin.")
   } catch (e) {
      return errorResponse("Error toggling admin.")
   }
}

export const toggleUserStatus = async (
   userId: string
): Promise<ServerResponse> => {
   try {
      const response = await httpsCallable(
         getFunctions(app, defaultRegion),
         "toggleUserStatus"
      )({
         uid: userId,
      })

      if (response && response.data) {
         // @ts-ignore
         return response.data.message as ServerResponse
      }

      return errorResponse("Error changing user status.")
   } catch (e) {
      return errorResponse("Error changing user status.")
   }
}

export const deleteUser = async (userId: string): Promise<ServerResponse> => {
   try {
      const response = await httpsCallable(
         getFunctions(app, defaultRegion),
         "deleteUser"
      )({
         uid: userId,
      })

      if (response && response.data) {
         // @ts-ignore
         return response.data.message as ServerResponse
      }

      return errorResponse("Error deleting user.")
   } catch (e) {
      return errorResponse("Error deleting user.")
   }
}
