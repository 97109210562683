import React from "react"
import { OrderStatus } from "../../../../helpers/types/order"
import { Tag } from "antd"
import { ordersOption } from "../../../../helpers/defaultData"

export default function OrderStatusRenderer({
   status,
}: {
   status: OrderStatus
}) {
   return (
      <Tag
         color={
            status === OrderStatus.submitted || status === OrderStatus.created
               ? "blue"
               : status === OrderStatus.customerReviewPending
               ? "orange"
               : status === OrderStatus.adminReviewPending
               ? "orange-inverse"
               : status === OrderStatus.approved
               ? "green-inverse"
               : "blue"
         }
         className='uppercase'>
         {ordersOption.find((q) => q.value === status)?.label}
      </Tag>
   )
}
