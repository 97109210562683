import {
   collection,
   getDocs,
   getFirestore,
   query,
   where,
} from "firebase/firestore"
import React, { useState } from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { Project, User } from "../../../../helpers/interfaces"
import {
   Button,
   Form,
   Input,
   Popconfirm,
   Select,
   Space,
   Table,
   Tag,
   message,
} from "antd"
import AddUpdateProject from "../../user/projects/AddUpdateProject"
import { useNavigate } from "react-router-dom"
import { deleteCustomDocument } from "../../../../helpers/firestoreHelper"
import UserOverview from "../combined/UserOverview"
import UserFormField from "../combined/UserFormField"

type ProjectsFilter = {
   projectName: string
   userId: string
}

export default function AdminProjectScreen() {
   const [projectsSnapshot, loading, error] = useCollection(
      query(collection(getFirestore(), "projects"))
   )

   const projectsData: Project[] = (projectsSnapshot?.docs?.map((d) => {
      return {
         id: d.id,
         ...d.data(),
      }
   }) ?? []) as Project[]

   const [filter, setFilter] = useState<ProjectsFilter>({
      projectName: "",
      userId: "",
   })
   const columns = [
      {
         title: "ID",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         sorter: (a: Project, b: Project) => a.name.localeCompare(b.name),
      },
      {
         title: "Created At",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text: any, record: Project) => {
            return new Date(
               record?.createdAt?.seconds * 1000 ?? new Date()
            ).toLocaleDateString()
         },
         sorter: (a: Project, b: Project) =>
            a?.createdAt?.seconds - b.createdAt?.seconds,
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (text: any, record: Project) => {
            return (
               <Tag className='uppercase' color='blue'>
                  {record.status}
               </Tag>
            )
         },
      },
      {
         title: "User",
         key: "username",
         render: (_: any, record: Project) => {
            return <UserOverview userId={record.userId} />
         },
      },
      {
         title: "Action",
         key: "action",
         render: (text: any, record: Project) => {
            return (
               <Space>
                  <AddUpdateProject type='update' project={record} />
                  <Popconfirm
                     title='Are you sure?'
                     onConfirm={async () => {
                        try {
                           message.info("Deleting project...")
                           const projectQuotes = await getDocs(
                              query(
                                 collection(getFirestore(), "quotes"),
                                 where("projectId", "==", record?.id)
                              )
                           )

                           for (
                              let i = 0;
                              i < projectQuotes?.docs?.length;
                              i++
                           ) {
                              await deleteCustomDocument(
                                 "quotes",
                                 projectQuotes?.docs[i]?.id
                              )
                           }

                           //get all quotes-requests
                           const projectQuoteRequests = await getDocs(
                              query(
                                 collection(getFirestore(), "quote-requests"),
                                 where("projectId", "==", record?.id)
                              )
                           )

                           for (
                              var i = 0;
                              i < projectQuoteRequests?.docs?.length;
                              i++
                           ) {
                              await deleteCustomDocument(
                                 "quote-requests",
                                 projectQuoteRequests?.docs[i]?.id
                              )
                           }

                           const response = await deleteCustomDocument(
                              "projects",
                              record?.id
                           )
                           message.success("Project deleted successfully")
                        } catch (e) {
                           message.error("Error deleting project")
                           console.log(e)
                        }
                     }}>
                     <Button danger type='link'>
                        Delete
                     </Button>
                  </Popconfirm>
               </Space>
            )
         },
      },
   ]
   return (
      <div className='flex flex-1 flex-col overflow-auto space-y-3'>
         <div className='flex flex-col'>
            <h1 className='mb-2 text-lg font-semibold'>Filter Projects</h1>
            <Form
               onFinish={(v) => {
                  setFilter({
                     projectName: v.projectName,
                     userId: v.userId,
                  })
               }}
               size='large'
               layout='vertical'>
               <div className='flex flex-row space-x-2'>
                  <Form.Item name={"projectName"}>
                     <Input placeholder='Enter project name' />
                  </Form.Item>
                  <UserFormField />
                  <Form.Item>
                     <Button htmlType='submit' type='primary'>
                        Search
                     </Button>
                  </Form.Item>
                  <Form.Item>
                     <Button
                        onClick={() => {
                           setFilter({
                              projectName: "",
                              userId: "",
                           })
                        }}
                        htmlType='reset'
                        type='primary'
                        danger>
                        Clear
                     </Button>
                  </Form.Item>
               </div>
            </Form>
         </div>
         <div className='flex flex-col overflow-auto'>
            <h1 className='mb-2 text-lg font-semibold'>Projects List</h1>
            <Table
               columns={columns}
               loading={loading}
               dataSource={projectsData.filter((p) => {
                  if (filter.projectName) {
                     return p.name
                        .toLowerCase()
                        .includes(filter.projectName.toLowerCase())
                  }

                  if (filter.userId && filter.userId !== "") {
                     return p.userId === filter.userId
                  }

                  return true
               })}
               rowKey={(r) => r?.id}
            />
         </div>
      </div>
   )
}
