import {
   collection,
   getDocs,
   getFirestore,
   orderBy,
   query,
   where,
} from "firebase/firestore"
import React from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { Button, message, Popconfirm, Space, Table, Tag } from "antd"
import AddUpdateProject from "./AddUpdateProject"
import { useNavigate } from "react-router-dom"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import { Project } from "../../../../helpers/interfaces"
import { deleteCustomDocument } from "../../../../helpers/firestoreHelper"

export default function ProjectsScreen() {
   const navigate = useNavigate()
   const [projectsSnapshot, loading, error] = useCollection(
      query(
         collection(getFirestore(), "projects"),
         where("userId", "==", getCurrentUserId()),
         orderBy("createdAt", "desc")
      )
   )

   const columns = [
      {
         title: "ID",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Created At",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text: any, record: Project) => {
            return new Date(
               record?.createdAt?.seconds * 1000 ?? new Date()
            ).toLocaleDateString()
         },
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (text: any, record: Project) => {
            return (
               <Tag className='uppercase' color='blue'>
                  {record.status}
               </Tag>
            )
         },
      },
      {
         title: "Action",
         key: "action",
         render: (text: any, record: Project) => {
            return (
               <Space>
                  <Button
                     type='link'
                     onClick={() => {
                        navigate(`/dashboard/projects/${record?.id}`)
                     }}>
                     View
                  </Button>
                  <AddUpdateProject type='update' project={record} />
                  <Popconfirm
                     title='Are you sure?'
                     onConfirm={async () => {
                        try {
                           message.info("Deleting project...")
                           const projectQuotes = await getDocs(
                              query(
                                 collection(getFirestore(), "quotes"),
                                 where("projectId", "==", record?.id),
                                 where("userId", "==", getCurrentUserId())
                              )
                           )

                           for (
                              let i = 0;
                              i < projectQuotes?.docs?.length;
                              i++
                           ) {
                              await deleteCustomDocument(
                                 "quotes",
                                 projectQuotes?.docs[i]?.id
                              )
                           }

                           //get all quotes-requests
                           const projectQuoteRequests = await getDocs(
                              query(
                                 collection(getFirestore(), "quote-requests"),
                                 where("projectId", "==", record?.id),
                                 where("userId", "==", getCurrentUserId())
                              )
                           )

                           for (
                              var i = 0;
                              i < projectQuoteRequests?.docs?.length;
                              i++
                           ) {
                              await deleteCustomDocument(
                                 "quote-requests",
                                 projectQuoteRequests?.docs[i]?.id
                              )
                           }

                           const response = await deleteCustomDocument(
                              "projects",
                              record?.id
                           )
                           message.success("Project deleted successfully")
                        } catch (e) {
                           message.error("Error deleting project")
                           console.log(e)
                        }
                     }}>
                     <Button danger type='link'>
                        Delete
                     </Button>
                  </Popconfirm>
               </Space>
            )
         },
      },
   ]

   return (
      <div className='flex flex-1 flex-col overflow-auto space-y-3'>
         <div className='flex flex-row justify-end'>
            <AddUpdateProject type='add' />
         </div>
         <div className='overflow-auto'>
            <Table
               columns={columns}
               loading={loading}
               dataSource={
                  (projectsSnapshot?.docs?.map((d) => {
                     return {
                        id: d.id,
                        ...d.data(),
                     }
                  }) ?? []) as Project[]
               }
               rowKey={(r) => r?.id}
            />
         </div>
      </div>
   )
}
