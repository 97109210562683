import { OrderActivity } from "../../../../helpers/types/order"
import DateRenderer from "../../user/combined/DateRenderer"
import { Tag } from "antd"
import ActivityRootView from "../../user/order/activitesViews/ActivityRootView"

export default function OrderAcceptActivityView({
   activity,
   userType,
}: {
   activity: OrderActivity
   userType: "admin" | "user"
}) {
   return (
      <ActivityRootView
         headerColorClass='bg-green-500 text-white font-bold'
         header={
            userType === "user"
               ? "You accepted this delivery"
               : "User accepted this delivery"
         }>
         <div className='flex flex-col space-y-2'>
            <div className='flex flex-row justify-between items-center'>
               <h1 className='font-semibold text-base'>{"ORDER COMPLETED"}</h1>
               <div className='flex flex-row items-center space-x-2'>
                  <DateRenderer full date={activity.createdAt} />
                  <Tag color='orange'>DELIVERY ACCEPTED</Tag>
               </div>
            </div>
            <p className='text-justify'>{activity.description}</p>
         </div>
      </ActivityRootView>
   )
}
