import { Button, message, Popconfirm, Space, Table, Tag } from "antd"
import {
   collection,
   deleteDoc,
   doc,
   getDoc,
   getDocs,
   getFirestore,
   orderBy,
   query,
   serverTimestamp,
   updateDoc,
   where,
} from "firebase/firestore"
import React from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../../../helpers/firestoreHelper"
import {
   ProjectStatus,
   Quotation,
   QuotationRequest,
   QuotationRequestType,
   QuoteRequestStatus,
   QuoteStatus,
} from "../../../../helpers/interfaces"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import Loading from "../../../Loading"
import DateRenderer from "../combined/DateRenderer"
import QuoteStatusRenderer from "../combined/QuoteStatusRenderer"
import AddUpdateQuotation from "./AddUpdateQuotation"
import UpdateQuotationRequest from "./UpdateQuotationRequest"

export default function ViewProjectScreen() {
   const { id } = useParams()
   const location = useLocation()
   const navigate = useNavigate()

   const { showNewQuotation } = (location?.state ?? {}) as {
      showNewQuotation: boolean
   }

   const [quotes, quoteLoading, quotesError] = useCollection(
      query(
         collection(getFirestore(), "quotes"),
         where("projectId", "==", id),
         where("visible", "==", true),
         where("userId", "==", getCurrentUserId()),
         orderBy("createdAt", "desc")
      )
   )

   if (quoteLoading)
      return (
         <div className='h-full w-full'>
            <Loading />
         </div>
      )

   if (quotesError) {
      if (location.key) {
         navigate(-1)
         return
      }

      navigate("/")
   }

   const columns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Date Created",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text) => <DateRenderer date={text} />,
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (status: QuoteStatus) => (
            <QuoteStatusRenderer status={status} />
         ),
      },
      {
         title: "Action",
         key: "action",
         render: (text, record: Quotation) => (
            <>
               <Button
                  type='link'
                  onClick={() => {
                     navigate("/dashboard/projects/quotations/" + record.id)
                  }}>
                  View
               </Button>

               <Popconfirm
                  onConfirm={async () => {
                     try {
                        message.info("Deleting quote")
                        const cd = doc(getFirestore(), "quotes", record.id)

                        await deleteDoc(cd)

                        message.success("Quote deleted")
                     } catch (e) {
                        console.log(e)
                        message.error("Error deleting quote")
                     }
                  }}
                  title='Are you sure you want to delete this quote?'>
                  <Button type='link' danger>
                     Delete
                  </Button>
               </Popconfirm>
            </>
         ),
      },
   ]
   return (
      <div className='flex flex-col w-full overflow-auto space-y-4'>
         <div className='flex flex-row justify-between items-center'>
            <h1 className='font-semibold text-lg'>Submitted Quotations</h1>
            <AddUpdateQuotation
               buttonType='primary'
               showInitial={showNewQuotation}
               onAdd={async (q) => {
                  q = q as QuotationRequest
                  q.userId = getCurrentUserId()
                  q.projectId = id
                  // @ts-ignore
                  q.createdAt = serverTimestamp()
                  // @ts-ignore
                  q.updatedAt = serverTimestamp()
                  q.submitType = "user"
                  q.debug = true
                  try {
                     message.info("Adding quote")

                     const newQuote = await addCustomDocument("quotes", {
                        userId: getCurrentUserId(),
                        projectId: id,
                        title: q.name,
                        createdAt: serverTimestamp(),
                        status: QuoteStatus.submitted,
                        visible: false,
                     })

                     await addCustomDocument("quote-requests", {
                        quoteId: newQuote.id,
                        status: QuoteRequestStatus.awaiting,
                        createdAt: serverTimestamp(),
                        updatedAt: serverTimestamp(),
                        projectId: id,
                        submitType: "user",
                        type: QuotationRequestType.submission,
                        ...q,
                     } as QuotationRequest)

                     await updateCustomDocument("quotes", newQuote.id, {
                        visible: true,
                     })

                     await updateCustomDocument("projects", id, {
                        status: ProjectStatus.submitted,
                     })

                     message.success("Quote added")

                     return true
                  } catch (e) {
                     console.log(e)
                     message.error("Error adding quote")
                     return false
                  }
               }}
               type='add'
            />
         </div>
         <div className='overflow-auto'>
            <Table
               columns={columns}
               rowKey={(r) => r.id}
               dataSource={quotes?.docs.map((d) => ({ ...d.data(), id: d.id }))}
            />
         </div>
      </div>
   )
}
