import {
   addDoc,
   collection,
   deleteDoc,
   doc,
   DocumentReference,
   getFirestore,
   setDoc,
   SetOptions,
   updateDoc,
} from "firebase/firestore"

export const addCustomDocument = async (
   collectionName: string,
   data: any
): Promise<DocumentReference<any>> => {
   return addDoc(collection(getFirestore(), collectionName), data)
}

export const updateCustomDocument = async (
   collectionName: string,
   docId: string,
   data: any = {},
   merge: boolean = true
) => {
   return setDoc(doc(getFirestore(), collectionName, docId), data, {
      merge,
   })
}

export const deleteCustomDocument = async (
   collectionName: string,
   docId: string
) => {
   return deleteDoc(doc(getFirestore(), collectionName, docId))
}
