import React, { useEffect, useState } from "react"
import {
   Quotation,
   QuotationRequest,
   QuotationRequestType,
   QuoteRequestStatus,
   QuoteStatus,
} from "../../../../helpers/interfaces"
import { Button, Form, Input, InputNumber, Modal, message } from "antd"
import { useCollection } from "react-firebase-hooks/firestore"
import {
   collection,
   getFirestore,
   orderBy,
   query,
   serverTimestamp,
   where,
} from "firebase/firestore"
import {
   CheckCircleFilled,
   EyeFilled,
   EyeInvisibleFilled,
} from "@ant-design/icons"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../../../helpers/firestoreHelper"
import Title from "../../combined/Title"
import ViewQuotationDetails from "../../../viewquotation/ViewQuotationDetails"
import ImageUploader from "../../combined/ImageUploader"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import { UploadType } from "../../../../helpers/uploadFileHelper"

interface AcceptQuotationProps {
   quote: Quotation
}

export default function AcceptQuotation({ quote }: AcceptQuotationProps) {
   const [open, setOpen] = useState(false)
   const [form] = Form.useForm()
   const [loading, setLoading] = useState(false)
   const [qrData, setQrData] = useState([])
   const [lastAwaitingQr, setLastAwaitingQr] = useState(null)
   const [totalPricing, setTotalPricing] = useState(0)
   const [showDetails, setShowDetails] = useState(false)
   const [uploadedFiles, setUploadedFiles] = useState([])
   const [fileUploading, setFileUploading] = useState(false)

   const [quotationRequests, quotationsLoading, quotationsError] =
      useCollection(
         query(
            collection(getFirestore(), "quote-requests"),
            where("quoteId", "==", quote.id),
            orderBy("createdAt", "asc")
         )
      )

   useEffect(() => {
      setQrData(
         quotationRequests?.docs.map((doc) => {
            const data = doc.data()
            return {
               ...data,
               id: doc.id,
            }
         }) ?? []
      )
   }, [quotationRequests])
   useEffect(() => {
      setLastAwaitingQr(
         qrData
            .reverse()
            // @ts-ignore
            .find((qr) => qr.status === QuoteRequestStatus.awaiting)
      )
   }, [qrData])

   useEffect(() => {
      if (lastAwaitingQr) {
         setTotalPricing(0)
         form.setFieldValue(
            "pricing",
            lastAwaitingQr.qty.map((q) => ({
               qty: q,
               price: 0,
            }))
         )
      }
   }, [lastAwaitingQr])
   return (
      <>
         <Modal
            open={open}
            okButtonProps={{
               loading,
               size: "large",
               form: "acceptance-form",
               htmlType: "submit",
               type: "primary",
               disabled: fileUploading,
               icon: <CheckCircleFilled />,
            }}
            width={800}
            okText='Accept'
            cancelButtonProps={{
               size: "large",
               disabled: loading || fileUploading,
               type: "dashed",
            }}
            onCancel={() => setOpen(false)}
            title='Accept Quote Request'>
            <div className='py-2 flex flex-col overflow-auto w-full'>
               <div className='flex flex-col space-y-2 mb-2'>
                  <div className='flex flex-row justify-end'>
                     <Button
                        type='primary'
                        size='large'
                        onClick={() => setShowDetails(!showDetails)}
                        icon={
                           showDetails ? <EyeInvisibleFilled /> : <EyeFilled />
                        }>
                        View Quotation Details
                     </Button>
                  </div>
                  <ViewQuotationDetails
                     show={showDetails}
                     quote={lastAwaitingQr}
                  />
               </div>
               <Form
                  form={form}
                  onValuesChange={(v, vx) => {
                     if (vx?.pricing) {
                        const pr = [...vx.pricing]
                        // get all pricing from form
                        const pricing = pr as {
                           qty: number
                           price: number
                        }[]
                        //calculate total pricing using for loop
                        let total = 0
                        for (let p of pricing) {
                           total += p.price
                        }

                        setTotalPricing(total)
                     }
                  }}
                  onFinish={async (v) => {
                     const totalPrice = v.pricing.reduce((acc, curr) => {
                        return acc + curr.price
                     }, 0)
                     //  @ts-ignore
                     const newQuotationRequest: QuotationRequest = {
                        userId: quote.userId,
                        projectId: quote.projectId,
                        quoteId: quote.id,
                        status: QuoteRequestStatus.accepted,
                        // @ts-ignore
                        createdAt: serverTimestamp(),
                        // @ts-ignore
                        updatedAt: serverTimestamp(),
                        submitType: "user",

                        files: uploadedFiles.map((f) => f.filename),
                        type: QuotationRequestType.modification,
                        acceptance: {
                           pricing: v.pricing,
                           remarks: v.remarks ?? "",
                           deliveryDays: v.days,
                           totalPrice,
                           quoteRequestId: lastAwaitingQr.id,
                        },
                        adminId: getCurrentUserId(),
                     }
                     setLoading(true)
                     try {
                        //update last awaiting qr
                        await updateCustomDocument(
                           "quote-requests",
                           lastAwaitingQr.id,
                           {
                              status: QuoteRequestStatus.accepted,
                           }
                        )
                        const r = await addCustomDocument(
                           "quote-requests",
                           newQuotationRequest
                        )
                        // @ts-ignore
                        const updatedQuote: Quotation = {
                           // @ts-ignore
                           updatedAt: serverTimestamp(),
                           status: QuoteStatus.accepted,
                           acceptedQuoteRequestId: r.id,
                        }
                        await updateCustomDocument(
                           "quotes",
                           quote.id,
                           updatedQuote
                        )
                        form.resetFields()
                        setOpen(false)
                        message.success("Quote request accepted successfully")
                     } catch (e) {
                        message.error("Failed to accept quote request")
                     }
                     setLoading(false)
                  }}
                  name='acceptance-form'
                  layout='vertical'>
                  <div className='flex flex-col space-y-4'>
                     <Title text={"Pricing "} />
                     <Form.List name={"pricing"}>
                        {(fields, { add, remove }) => {
                           return (
                              <div className='flex flex-col space-y-0.5'>
                                 {fields.map((field, index) => (
                                    <div className='flex flex-col space-y-2'>
                                       <div className='flex flex-row space-x-2'>
                                          <Form.Item
                                             label='Qty'
                                             name={[field.name, "qty"]}
                                             rules={[
                                                {
                                                   required: true,
                                                   message:
                                                      "Please enter quantity",
                                                },
                                             ]}>
                                             <InputNumber readOnly />
                                          </Form.Item>
                                          <Form.Item
                                             className='flex-1'
                                             label='Price'
                                             name={[field.name, "price"]}
                                             rules={[
                                                {
                                                   required: true,
                                                   message:
                                                      "Please enter price",
                                                },
                                                {
                                                   type: "number",
                                                   min: 0,
                                                   message:
                                                      "Price cannot be negative",
                                                },
                                             ]}>
                                             <InputNumber
                                                prefix='$'
                                                style={{
                                                   width: "100%",
                                                }}
                                                className='w-full'
                                                min={0}
                                             />
                                          </Form.Item>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           )
                        }}
                     </Form.List>
                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: "Please enter delivery time",
                           },
                           {
                              type: "number",
                              min: 0,
                              message: "Delivery time cannot be negative",
                           },
                           {
                              type: "integer",
                              message: "Delivery time must be an integer",
                           },
                        ]}
                        name={"days"}
                        label='Delivery Time'>
                        <InputNumber
                           type=''
                           prefix='Days'
                           style={{
                              width: "100%",
                           }}
                           min={0}
                           placeholder='Enter no. of days'
                        />
                     </Form.Item>
                     <Form.Item name={"remarks"} label='Remarks'>
                        <Input.TextArea placeholder='Remarks' />
                     </Form.Item>

                     <div className='flex flex-row justify-between'>
                        <Title text='Total Pricing' />
                        <Title text={`$${totalPricing.toFixed(2)}`} />
                     </div>

                     <label>Upload File</label>
                     <ImageUploader
                        onChange={(a) => setUploadedFiles(a)}
                        userId={quote.userId}
                        type={UploadType.quotes}
                        onUploadStateChange={(a) => setFileUploading(a)}
                     />
                  </div>
               </Form>
            </div>
         </Modal>
         <Button
            onClick={() => setOpen(true)}
            icon={<CheckCircleFilled />}
            size='large'
            type='primary'>
            Accept
         </Button>
      </>
   )
}
