import { Alert, Button, Form, Input, message } from "antd"
import React, { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons"
import {
   useAuthState,
   useCreateUserWithEmailAndPassword,
} from "react-firebase-hooks/auth"
import { getAuth, updateProfile } from "firebase/auth"
import { doc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore"
import Loading from "../Loading"

export default function RegisterScreen() {
   const navigate = useNavigate()
   const [currentUser, loadingUser, userError] = useAuthState(getAuth())

   const [isRegistering, setIsRegistering] = useState(false)
   const [createUser, user, registering, error] =
      useCreateUserWithEmailAndPassword(getAuth())

   if (loadingUser) return <Loading />

   if (currentUser && !isRegistering) return <Navigate to='/dashboard' />

   return (
      <div
         className='h-full w-full flex flex-row justify-end bg-no-repeat bg-cover'
         style={{
            backgroundImage: `url("${require("../../assets/accountback.png")}")`,
         }}>
         <div className='mr-[100px] flex flex-col justify-center items-center'>
            <div className='w-[400px] rounded-lg bg-white h-auto flex flex-col py-10 px-5'>
               <div className='space-y-4 mb-4'>
                  <h2 className='text-3xl mb-2 font-semibold text-center'>
                     Welcome to Evergo Packaging
                  </h2>
                  <h2 className='text-xl mb-2'>Register New Account</h2>
               </div>

               <Form
                  layout='vertical'
                  size='large'
                  onFinish={async (v) => {
                     if (isRegistering) return
                     if (v.confirmPassword !== v.password) {
                        message.error("Password does not match")
                        return
                     }

                     const userCreateResponse = await createUser(
                        v.email,
                        v.password
                     )

                     if (userCreateResponse) {
                        try {
                           setIsRegistering(true)
                           await updateProfile(userCreateResponse.user, {
                              displayName: v.fullname,
                           })

                           message.success("Account created successfully")

                           //add record record to firestore users collection

                           await setDoc(
                              doc(
                                 getFirestore(),
                                 "users",
                                 userCreateResponse.user.uid
                              ),
                              {
                                 fullname: v.fullname,
                                 email: v.email,
                                 role: "user",
                                 createdAt: serverTimestamp(),
                                 updatedAt: serverTimestamp(),
                                 isEnabled: true,
                                 userId: userCreateResponse.user.uid,
                              }
                           )
                           setIsRegistering(false)

                           navigate("/login")
                        } catch (e) {
                           message.error("Account creation failed, try again.")
                           setIsRegistering(false)
                        }
                     } else {
                        message.error("Account creation failed")
                        setIsRegistering(false)
                     }
                  }}
                  name='loginForm'>
                  <div className='flex flex-col '>
                     <Form.Item
                        name={"fullname"}
                        rules={[
                           {
                              required: true,
                              message: "Please input your full name",
                           },
                           {
                              min: 8,
                              message:
                                 "Full name must be at least 8 characters",
                           },
                           {
                              max: 20,
                              message:
                                 "Full name must be at most 20 characters",
                           },
                        ]}>
                        <Input
                           prefix={<UserOutlined />}
                           placeholder='Enter your full name'
                        />
                     </Form.Item>
                     <Form.Item
                        name={"email"}
                        rules={[
                           {
                              required: true,
                              message: "Please input your email address",
                           },
                           {
                              type: "email",
                              message: "Please input a valid email address",
                           },
                        ]}>
                        <Input
                           prefix={<MailOutlined />}
                           placeholder='Enter your email address'
                        />
                     </Form.Item>
                     <Form.Item
                        name={"password"}
                        rules={[
                           {
                              required: true,
                              message: "Please input your password",
                           },
                           {
                              min: 8,
                              message: "Password must be at least 8 characters",
                           },
                           {
                              max: 20,
                              message: "Password must be at most 20 characters",
                           },
                        ]}>
                        <Input.Password
                           name='password'
                           prefix={<LockOutlined />}
                           placeholder='Enter your password'
                        />
                     </Form.Item>
                     <Form.Item
                        name={"confirmPassword"}
                        rules={[
                           {
                              required: true,
                              message: "Please input confirm password",
                           },
                           {
                              min: 8,
                              message: "Password must be at least 8 characters",
                           },
                           {
                              max: 20,
                              message: "Password must be at most 20 characters",
                           },
                        ]}>
                        <Input.Password
                           name='confirmPassword'
                           prefix={<LockOutlined />}
                           placeholder='Enter your password'
                        />
                     </Form.Item>
                  </div>
                  {error && (
                     <Alert type='error' message={error.code} showIcon />
                  )}
                  <div className='mt-5 space-y-3 flex flex-col items-center'>
                     <Button
                        type='primary'
                        htmlType='submit'
                        loading={registering}
                        className='w-full'>
                        REGISTER
                     </Button>

                     <div className='w-full h-[1px] bg-gray-200' />

                     <Button
                        onClick={() => {
                           navigate("/login")
                        }}
                        className='w-full'>
                        LOGIN
                     </Button>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   )
}
