import { Breadcrumb, Button } from "antd"
import React, { useEffect, useState } from "react"
import Section from "./Section"
import Loading from "../../Loading"
import {
   Order,
   OrderPaymentOptions,
   OrderPickupOption,
   OrderStatus,
} from "../../../helpers/types/order"
import { useDocument } from "react-firebase-hooks/firestore"
import { EvergoCollections } from "../../../helpers/types/firestore"
import { doc, getFirestore } from "firebase/firestore"
import DateRenderer from "../user/combined/DateRenderer"
import { Time } from "../user/order/OrderViewScreen"
import { CiDeliveryTruck } from "react-icons/ci"
import { BsPerson } from "react-icons/bs"
import ImageAssetPreview from "./ImageAssetPreview"
import DeliverOrderModal from "../admin/orders/DeliverOrderModal"
import AcceptOrderModal from "../user/order/AcceptOrderModal"
import RejectOrderModal from "../user/order/RejectOrderModal"

interface Props {
   userType: "admin" | "user"
   orderId: string
   children: React.ReactNode
   activitiesLoading: boolean
   onDeliverClicked?: () => void
   orderRef: string
}

export default function OrderContainer({
   userType,
   orderId,
   activitiesLoading,
   children,
   onDeliverClicked,
   orderRef,
}: Props) {
   const [orderSnapshot, orderLoading, orderError] = useDocument(
      doc(getFirestore(), EvergoCollections.orders, orderRef)
   )
   const orderData = (orderSnapshot?.data?.() ?? {}) as Order
   const [isLate, setIsLate] = useState(false)

   const [remainingTime, setRemainingTime] = useState<Time | null>(null)
   useEffect(() => {
      const orderData = orderSnapshot?.data?.() as Order

      if (!orderData) return

      //    @ts-ignore
      const deliveryDate = new Date(orderData.deliveryDate?.seconds * 1000)

      if (new Date() > deliveryDate) {
         setRemainingTime({
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
         })
         setIsLate(true)
         return
      }

      const interval = setInterval(() => {
         const now = new Date().getTime()

         const distance = deliveryDate.getTime() - now

         const days = Math.floor(distance / (1000 * 60 * 60 * 24))
         const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
         )
         const min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
         const sec = Math.floor((distance % (1000 * 60)) / 1000)

         setRemainingTime({
            days,
            hours,
            min,
            sec,
         })
      }, 1000)

      return () => clearInterval(interval)
   }, [orderSnapshot])

   return (
      <div className='flex flex-col h-full w-full space-y-2 overflow-auto'>
         <Breadcrumb
            items={[
               {
                  title: "Orders",
               },
               {
                  title: orderId,
               },
            ]}
         />
         <div className='grid grid-cols-6 flex-1 gap-4'>
            <Section title='Order Activites ' className='col-span-4 '>
               {activitiesLoading ? (
                  <div className='flex flex-1 justify-center items-center'>
                     <Loading />
                  </div>
               ) : (
                  <div className='flex flex-col  space-y-4 p-4 overflow-scroll'>
                     {children}
                  </div>
               )}
            </Section>

            <div className='col-span-2 flex flex-col h-full overflow-scroll'>
               <div className='flex flex-1 flex-col space-y-4'>
                  {!orderLoading &&
                     orderData &&
                     userType === "admin" &&
                     orderData.status !== OrderStatus.approved && (
                        <Section title='Order Delivery'>
                           <div className='p-4 flex flex-col'>
                              <DeliverOrderModal
                                 deliverAgain={
                                    orderData.status ===
                                       OrderStatus.customerReviewPending ||
                                    orderData.status ===
                                       OrderStatus.adminReviewPending
                                 }
                                 orderId={orderRef}
                                 userId={orderData.userId}
                              />
                           </div>
                        </Section>
                     )}
                  {userType === "user" &&
                     orderData.status === OrderStatus.customerReviewPending && (
                        <Section title='Actions'>
                           <div className='p-4 flex flex-row gap-2'>
                              <AcceptOrderModal
                                 orderId={orderRef}
                                 userId={orderData.userId}
                              />
                              <RejectOrderModal
                                 orderId={orderRef}
                                 userId={orderData.userId}
                              />
                           </div>
                        </Section>
                     )}
                  <Section title='Order Details'>
                     <Loading loading={orderLoading}>
                        <div className='grid grid-cols-2 gap-2 p-4'>
                           <h1 className='font-semibold '>Total Price</h1>
                           <h1 className='text-right'>
                              ${(orderData.totalPrice ?? 0).toFixed?.(2)}
                           </h1>

                           {/* delivery date */}
                           <h1 className='font-semibold '>Delivery Date</h1>
                           <h1 className='text-right'>
                              <DateRenderer
                                 full
                                 // @ts-ignore
                                 date={orderData.deliveryDate}
                              />
                           </h1>
                        </div>
                     </Loading>
                  </Section>
                  {(orderData.status === OrderStatus.created ||
                     orderData.status === OrderStatus.adminReviewPending) && (
                     <Section title='Order Time'>
                        <Loading loading={orderLoading}>
                           <div
                              className={
                                 "flex flex-row justify-center space-x-4 p-4 text-center " +
                                 (isLate ? "text-red-500" : "")
                              }>
                              <div className='flex flex-col'>
                                 <h1>
                                    {remainingTime?.days.toLocaleString(
                                       "en-US",
                                       {
                                          minimumIntegerDigits: 2,
                                          useGrouping: false,
                                       }
                                    )}
                                 </h1>
                                 <h1>Days</h1>
                              </div>
                              <div className='w-[1px] bg-gray-400' />
                              <div className='flex flex-col'>
                                 <h1>
                                    {remainingTime?.hours.toLocaleString(
                                       "en-US",
                                       {
                                          minimumIntegerDigits: 2,
                                          useGrouping: false,
                                       }
                                    )}
                                 </h1>
                                 <h1>Hours</h1>
                              </div>
                              <div className='w-[1px] bg-gray-400' />
                              <div className='flex flex-col'>
                                 <h1>
                                    {remainingTime?.min.toLocaleString(
                                       "en-US",
                                       {
                                          minimumIntegerDigits: 2,
                                          useGrouping: false,
                                       }
                                    )}
                                 </h1>
                                 <h1>Minutes</h1>
                              </div>
                              <div className='w-[1px] bg-gray-400' />
                              <div className='flex flex-col'>
                                 <h1>
                                    {remainingTime?.sec.toLocaleString(
                                       "en-US",
                                       {
                                          minimumIntegerDigits: 2,
                                          useGrouping: false,
                                       }
                                    )}
                                 </h1>
                                 <h1>Seconds</h1>
                              </div>
                           </div>
                        </Loading>
                     </Section>
                  )}
                  <Section title='Delivery Details'>
                     <Loading loading={orderLoading}>
                        <div className='grid grid-cols-2 gap-2 p-4'>
                           <h1 className='font-semibold '>Pickup</h1>

                           <div className='text-right flex flex-row justify-end space-x-2 items-center'>
                              {orderData.pickupOption ===
                              OrderPickupOption.delivery ? (
                                 <CiDeliveryTruck className='text-green-600 text-xl ' />
                              ) : (
                                 <BsPerson className='text-green-600 text-xl ' />
                              )}
                              <h1>
                                 {orderData.pickupOption ===
                                 OrderPickupOption.delivery
                                    ? "Delivery"
                                    : "Self"}
                              </h1>
                           </div>

                           {orderData.pickupOption ===
                              OrderPickupOption.delivery && (
                              <>
                                 <h1 className='font-semibold'>
                                    {"Address 1"}
                                 </h1>
                                 <h1 className='text-right'>
                                    {orderData.deliveryAddress.address1}
                                 </h1>
                                 {orderData.deliveryAddress.address2 && (
                                    <>
                                       <h1 className='font-semibold'>
                                          {"Address 2"}
                                       </h1>
                                       <h1 className='text-right'>
                                          {orderData.deliveryAddress.address2 ??
                                             ""}
                                       </h1>
                                    </>
                                 )}
                                 <h1 className='font-semibold'>{"City"}</h1>
                                 <h1 className='text-right'>
                                    {orderData.deliveryAddress.city}
                                 </h1>

                                 <h1 className='font-semibold'>{"State"}</h1>
                                 <h1 className='text-right'>
                                    {orderData.deliveryAddress.state}
                                 </h1>

                                 <h1 className='font-semibold'>
                                    {"Postal Code"}
                                 </h1>
                                 <h1 className='text-right'>
                                    {orderData.deliveryAddress.postalCode}
                                 </h1>
                              </>
                           )}
                        </div>
                     </Loading>
                  </Section>
                  <Section title='Payment'>
                     <Loading loading={orderLoading}>
                        <div className='grid grid-cols-2 gap-2 p-4'>
                           <h1 className='font-bold'>Payment Type</h1>
                           <h1 className='text-right'>
                              {orderData.paymentOption ===
                              OrderPaymentOptions.cash
                                 ? "Cash"
                                 : OrderPaymentOptions.bankTransfer ===
                                   orderData.paymentOption
                                 ? "Bank Transfer"
                                 : "Other"}
                           </h1>
                           <div className='col-span-2 flex flex-row gap-2 flex-wrap'>
                              {OrderPaymentOptions.bankTransfer ===
                                 orderData.paymentOption &&
                                 orderData.bankPayment?.attachments.map((a) => {
                                    return (
                                       <ImageAssetPreview
                                          userId={orderData.userId}
                                          id={a}
                                       />
                                    )
                                 })}
                           </div>
                        </div>
                     </Loading>
                  </Section>
               </div>
            </div>
         </div>
      </div>
   )
}
