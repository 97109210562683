import { Button, Space, Table, Tag } from "antd"
import { Order } from "../../../../helpers/types/order"
import { useNavigate } from "react-router-dom"
import { useCollection } from "react-firebase-hooks/firestore"
import {
   collection,
   getFirestore,
   orderBy,
   query,
   where,
} from "firebase/firestore"
import { EvergoCollections } from "../../../../helpers/types/firestore"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import DateRenderer from "../combined/DateRenderer"
import OrderStatusRenderer from "../combined/OrderStatusRenderer"

export default function OrdersScreen() {
   const navigate = useNavigate()
   const [orderSnapshot, orderLoading, orderError] = useCollection(
      query(
         collection(getFirestore(), EvergoCollections.orders),
         where("userId", "==", getCurrentUserId()),
         orderBy("createdAt", "desc")
      )
   )

   const columns = [
      {
         title: "ID",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Created At",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (text: any, record: Order) => {
            return new Date(
               record?.createdAt?.seconds * 1000 ?? new Date()
            ).toLocaleDateString()
         },
      },
      {
         title: "Last Activity",
         dataIndex: "updatedAt",
         key: "updatedAt",
         render: (text: any, record: Order) => (
            <DateRenderer full date={record?.updatedAt} />
         ),
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (text: any, record: Order) => {
            return <OrderStatusRenderer status={record.status} />
         },
      },
      {
         title: "Action",
         key: "action",
         render: (text: any, record: Order) => {
            return (
               <Space>
                  <Button
                     type='link'
                     onClick={() => {
                        navigate(`/dashboard/orders/${record?.ref}`)
                     }}>
                     View
                  </Button>
               </Space>
            )
         },
      },
   ]

   return (
      <div className='flex flex-1 flex-col overflow-auto space-y-3'>
         <div className='overflow-auto'>
            <Table
               columns={columns}
               loading={orderLoading}
               dataSource={
                  (orderSnapshot?.docs?.map((d) => {
                     return {
                        ref: d.id,
                        ...d.data(),
                     }
                  }) ?? []) as Order[]
               }
               rowKey={(r) => r?.id}
            />
         </div>
      </div>
   )
}
