import { CloseCircleFilled } from "@ant-design/icons"
import { Button, Form, Input, Modal, message } from "antd"
import React, { useState } from "react"
import QuotationRequestIssueFormField from "../combined/QuotationRequestIssueFormField"
import {
   Quotation,
   QuotationRequest,
   QuotationRequestType,
   QuoteRequestStatus,
   QuoteStatus,
} from "../../../../helpers/interfaces"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../../../helpers/firestoreHelper"
import {
   collection,
   getFirestore,
   orderBy,
   query,
   serverTimestamp,
   where,
} from "firebase/firestore"
import { useCollection } from "react-firebase-hooks/firestore"
import ImageUploader from "../../combined/ImageUploader"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import { UploadType } from "../../../../helpers/uploadFileHelper"

export default function RejectQuotation({ quote }: { quote: Quotation }) {
   const [open, setOpen] = useState(false)
   const [form] = Form.useForm()
   const [loading, setLoading] = useState(false)
   const [uploadedFiles, setUploadedFiles] = useState([])
   const [fileUploading, setFileUploading] = useState(false)

   const [quotationRequests, quotationsLoading, quotationsError] =
      useCollection(
         query(
            collection(getFirestore(), "quote-requests"),
            where("quoteId", "==", quote.id),
            orderBy("createdAt", "asc")
         )
      )

   const qrData =
      quotationRequests?.docs.map((doc) => {
         const data = doc.data()
         return {
            ...data,
            id: doc.id,
         }
      }) ?? []

   // @ts-ignore
   const lastAwaitingQr = qrData
      .reverse()
      // @ts-ignore
      .find((qr) => qr.status === QuoteRequestStatus.awaiting)

   return (
      <>
         <Modal
            open={open}
            okButtonProps={{
               loading,
               size: "large",
               form: "rejection-form",
               htmlType: "submit",
               danger: true,
               disabled: fileUploading,
               icon: <CloseCircleFilled />,
            }}
            okText='Reject'
            cancelButtonProps={{
               size: "large",
               disabled: loading || fileUploading,
               type: "primary",
            }}
            onCancel={() => setOpen(false)}
            title='Reject Quote Request'>
            <div className='py-2'>
               <Form
                  form={form}
                  onFinish={async (v) => {
                     // @ts-ignore
                     const newQuotationRequest: QuotationRequest = {
                        userId: quote.userId,
                        projectId: quote.projectId,
                        quoteId: quote.id,
                        status: QuoteRequestStatus.rejected,
                        issue: {
                           remarks: v.remarks,
                           type: v.rejectionType,
                        },
                        // @ts-ignore
                        createdAt: serverTimestamp(),
                        // @ts-ignore
                        updatedAt: serverTimestamp(),
                        submitType: "user",
                        type: QuotationRequestType.modification,
                        files: uploadedFiles.map((f) => f.filename),
                        adminId: getCurrentUserId(),
                     }
                     setLoading(true)
                     try {
                        //update last awaiting qr

                        await updateCustomDocument(
                           "quote-requests",
                           lastAwaitingQr.id,
                           {
                              status: QuoteRequestStatus.rejected,
                           }
                        )

                        const r = await addCustomDocument(
                           "quote-requests",
                           newQuotationRequest
                        )

                        // @ts-ignore
                        const updatedQuote: Quotation = {
                           // @ts-ignore
                           updatedAt: serverTimestamp(),
                           status: QuoteStatus.modificationRequested,
                        }

                        await updateCustomDocument(
                           "quotes",
                           quote.id,
                           updatedQuote
                        )

                        form.resetFields()
                        setOpen(false)

                        message.success("Quote request rejected")
                     } catch (e) {
                        message.error("Failed to reject quote request")
                     }

                     setLoading(false)
                  }}
                  name='rejection-form'
                  layout='vertical'>
                  <div className='flex flex-col space-y-4'>
                     <QuotationRequestIssueFormField />
                     <Form.Item
                        name={"remarks"}
                        label='Remarks'
                        rules={[
                           {
                              required: true,
                              message: "Please enter remarks",
                           },
                           {
                              min: 10,
                              message: "Remarks must be at least 10 characters",
                           },
                           {
                              max: 5000,
                              message: "Remarks must be at most 100 characters",
                           },
                        ]}>
                        <Input.TextArea rows={3} placeholder='Remarks' />
                     </Form.Item>
                     <label>Upload Files</label>
                     <ImageUploader
                        type={UploadType.quotes}
                        userId={quote.userId}
                        onChange={(f) => setUploadedFiles(f)}
                        onUploadStateChange={(u) => setFileUploading(u)}
                     />
                  </div>
               </Form>
            </div>
         </Modal>
         <Button
            onClick={() => setOpen(true)}
            icon={<CloseCircleFilled />}
            size='large'
            danger>
            Reject
         </Button>
      </>
   )
}
