import {
   CheckSquareOutlined,
   DashboardFilled,
   LeftCircleFilled,
   LogoutOutlined,
   OrderedListOutlined,
   PlusSquareOutlined,
   ProfileFilled,
   ProjectFilled,
   UserOutlined,
   UserSwitchOutlined,
} from "@ant-design/icons"
import { Avatar, Layout, Menu, MenuProps, message } from "antd"
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import Loading from "../Loading"
import { adminAppVersion, clientAppVersion } from "../../helpers/versions"
import { useDocument } from "react-firebase-hooks/firestore"
import { doc, getFirestore, onSnapshot } from "firebase/firestore"
import { getCurrentUserId } from "../../helpers/userAuthHelpers"
import { UserProfile } from "../../helpers/interfaces"
import { getDownloadURL, getStorage, ref } from "firebase/storage"

const { Header, Content, Sider } = Layout

type MenuItem = Required<MenuProps>["items"][number]

function getItem(
   label: React.ReactNode,
   key: React.Key,
   icon?: React.ReactNode,
   children?: MenuItem[]
): MenuItem {
   return {
      key,
      icon,
      children,
      label,
   } as MenuItem
}

const items: MenuItem[] = [
   getItem("Dashboard", "home", <DashboardFilled />),
   getItem("Projects", "projects", <ProjectFilled />),
   getItem("Orders", "orders", <OrderedListOutlined />),
   getItem("Profile", "profile", <ProfileFilled />),
]

const adminSideItems: MenuItem[] = [
   getItem("Dashboard", "home", <DashboardFilled />),
   getItem("Projects", "projects", <ProfileFilled />),
   getItem("Quotations", "quotations", <PlusSquareOutlined />),
   getItem("Orders", "orders", <CheckSquareOutlined />),
   getItem("Users", "users", <UserOutlined />),
   getItem("Admins", "admins", <UserSwitchOutlined />),
]

const getCurrentMenuItem = (location: string) => {
   if (location.startsWith("/dashboard/home")) return "home"
   if (location.startsWith("/dashboard/projects")) return "projects"
   if (location.startsWith("/dashboard/orders")) return "orders"
   if (location.startsWith("/dashboard/profile")) return "profile"
   if (location.startsWith("admin/dashboard/quotations")) return "quotations"
   if (location.startsWith("admin/dashboard/projects")) return "projects"
   if (location.startsWith("admin/dashboard")) return "home"
   if (location.startsWith("admin/dashboard/orders")) return "orders"
   if (location.startsWith("admin/dashboard/users")) return "users"
   if (location.startsWith("admin/dashboard/admins")) return "admins"

   return "home"
}

export default function Dashboard({ type }: { type: "admin" | "user" }) {
   const [currentUser, userLoading, userError] = useAuthState(getAuth())
   const [collapsed, setCollapsed] = useState(false)
   const [verifying, setVerifying] = useState(false)
   const [verified, setVerified] = useState(false)

   const navigate = useNavigate()
   const location = useLocation()
   const [currentPath, setCurrentPath] = useState(["home"])
   const isAdmin =
      location?.pathname?.startsWith?.("/admin/dashboard/") ?? false

   const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null)

   useEffect(() => {
      let s = null
      onAuthStateChanged(getAuth(), (user) => {
         if (!user) return
         s = onSnapshot(
            doc(getFirestore(), "users", user.uid),
            (snapshot) => {
               setProfile(snapshot.data() as UserProfile)
            },
            (e) => {
               console.error(e)
            }
         )
      })

      return () => {
         s?.()
      }
   }, [])

   const [profile, setProfile] = useState<UserProfile | null>()

   useEffect(() => {
      if (!profile) return
      const updateProfileImageUrl = async () => {
         if (profile?.image) {
            try {
               const url = await getDownloadURL(
                  ref(getStorage(), getCurrentUserId() + "/" + profile.image)
               )
               setProfileImageUrl(url)
            } catch (e) {
               console.error("Error getting profile image", e)
            }
         }
      }

      updateProfileImageUrl()
   }, [profile])

   useEffect(() => {
      const pn =
         type === "user"
            ? location.pathname.replace("/dashboard/", "")
            : location.pathname.replace("/admin/dashboard/", "")
      setCurrentPath([pn.split("/")[0]])
   }, [location.pathname])

   useEffect(() => {
      const verify = async () => {
         setVerifying(true)

         //get user claims
         const idTokenResult = await currentUser.getIdTokenResult()
         const isAdmin = idTokenResult.claims?.admin ?? false

         if (type === "admin" && isAdmin) {
            setVerified(true)
         } else if (type === "user" && !isAdmin) {
            setVerified(true)
         } else if (type === "admin" && !isAdmin) {
            message.error("You are not authorized to access this page.")
            navigate("/dashboard")
            setVerified(true)
         } else if (type === "user" && isAdmin) {
            message.error("You are not authorized to access this page.")
            navigate("/admin/dashboard")
            setVerified(true)
         }

         setVerifying(false)
      }

      if (currentUser) verify()
   }, [currentUser])

   if (userLoading || verifying || !verified) return <Loading />

   if (!currentUser) return <Navigate to='/login' />

   return (
      <Layout style={{ minHeight: "100vh" }}>
         <Sider
            collapsible
            id='side-menu'
            collapsed={collapsed}
            className='bg-secondary flex flex-col'
            onCollapse={(value) => setCollapsed(value)}>
            <div className='p-4 h-16 bg-secondary flex flex-col  space-y-2 justify-center items-center'>
               <img src={require("../../assets/logo.png")} alt='logo' />
            </div>
            <div className='flex flex-col flex-1'>
               <h1
                  className={
                     "font-semibold text-white self-center text-lg " +
                     (collapsed ? "hidden" : "")
                  }>
                  {isAdmin ? "Admin Panel" : "User Panel"}
               </h1>
               <div className='pt-20 flex flex-col flex-1'>
                  <Menu
                     onClick={(k) => {
                        navigate(
                           (type === "user" ? "" : "/admin") +
                              `/dashboard/${k.key}`
                        )
                        setCurrentPath([k.key])
                     }}
                     selectedKeys={currentPath}
                     className='bg-secondary flex-1'
                     defaultSelectedKeys={[
                        getCurrentMenuItem(location.pathname),
                     ]}
                     mode='inline'
                     items={type === "user" ? items : adminSideItems}
                  />
               </div>

               <div className='text-white text-center font-semibold m-0 mb-2'>
                  {type === "admin" ? "A" : "C"}V
                  {type === "admin" ? adminAppVersion : clientAppVersion}
               </div>
               <div
                  onClick={async () => {
                     await signOut(getAuth())
                     window.location.href = "/"
                  }}
                  className='text-white hover:text-red-500 hover:animate-pulse cursor-pointer text-base flex items-center flex-row space-x-2 justify-center'>
                  <LogoutOutlined className='font-bold' />
                  <h1>Logout</h1>
               </div>
            </div>
         </Sider>
         <Layout className='site-layout'>
            <Header
               style={{ padding: 0, background: "white" }}
               className='drop-shadow-md h-3 '>
               <div className='flex flex-row justify-between items-center h-full px-5  uppercase'>
                  <div
                     className='flex flex-row items-center space-x-2 cursor-pointer'
                     onClick={() => {
                        if (location.key) {
                           navigate(-1)
                        }
                     }}>
                     <LeftCircleFilled className='text-xl' />
                     <h1 className='font-semibold text-lg'>
                        {getCurrentMenuItem(location.pathname)}
                     </h1>
                  </div>
                  <div className='flex flex-row space-x-2 items-center'>
                     <h2>{currentUser.displayName}</h2>
                     <Avatar src={profileImageUrl}>
                        {currentUser.displayName?.charAt(0) ?? "A"}
                     </Avatar>
                  </div>
               </div>
            </Header>
            <Content>
               <div className='w-full bg-white h-full flex flex-1 p-4'>
                  <Outlet />
               </div>
            </Content>
         </Layout>
      </Layout>
   )
}
