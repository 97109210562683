import React from "react"
import { OrderActivity } from "../../../../helpers/types/order"
import DateRenderer from "../../user/combined/DateRenderer"
import { Tag } from "antd"
import ImageAssetPreview from "../ImageAssetPreview"
import ActivityRootView from "../../user/order/activitesViews/ActivityRootView"

export default function OrderDeliveryActivityView({
   activity,
   userType,
}: {
   activity: OrderActivity
   userType: "admin" | "user"
}) {
   return (
      <ActivityRootView
         headerColorClass='bg-cyan-500 text-white font-bold'
         header={
            userType === "user"
               ? "Admin delivered this order"
               : "You delivered this order"
         }>
         <div className='flex flex-col space-y-2'>
            <div className='flex flex-row justify-between items-center'>
               <h1 className='font-semibold text-base'>Order Delivered</h1>
               <div className='flex flex-row items-center space-x-2'>
                  <DateRenderer full date={activity.createdAt} />
                  <Tag color='cyan'>DELIVERED</Tag>
               </div>
            </div>
            <p className='text-justify'>{activity.description}</p>
            <h1 className='font-semibold'>Attached Files</h1>
            {(activity.attachedFiles ?? []).length > 0 && (
               <div className='flex flex-row flex-wrap gap-2'>
                  {activity.attachedFiles?.map?.((file) => (
                     <ImageAssetPreview id={file} userId={activity.userId} />
                  ))}
               </div>
            )}
         </div>
      </ActivityRootView>
   )
}
