import { ConfigProvider } from "antd"
import "./App.css"
import { StyleProvider } from "@ant-design/cssinjs"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import LoginScreen from "./components/screens/LoginScreen"
import RegisterScreen from "./components/screens/RegisterScreen"
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen"
import "./helpers/firebase"
import Dashboard from "./components/screens/Dashboard"
import ViewProjectScreen from "./components/screens/user/viewprojects/ViewProjectScreen"
import ViewQuotationScreen from "./components/viewquotation/ViewQuotationScreen"
import HomeScreen from "./components/screens/user/home/HomeScreen"
import OrdersScreen from "./components/screens/user/order/OrdersScreen"
import ProjectsScreen from "./components/screens/user/projects/ProjectsScreen"
import ProfileScreen from "./components/screens/user/profile/ProfileScreen"
import AdminHomeScreen from "./components/screens/admin/home/AdminHomeScreen"
import AdminOrdersScreen from "./components/screens/admin/orders/AdminOrdersScreen"
import AdminProjectScreen from "./components/screens/admin/projects/AdminProjectScreen"
import AdminQuotationsScreen from "./components/screens/admin/new-quotations/AdminQuotationsScreen"
import AdminUsersScreen from "./components/screens/admin/users/AdminUsersScreen"
import AdminsScreen from "./components/screens/admin/admins/AdminsScreen"
import AdminViewQuotationScreen from "./components/screens/admin/new-quotations/AdminViewQuotationScreen"
import OrderViewScreen from "./components/screens/user/order/OrderViewScreen"
import AdminOrderViewScreen from "./components/screens/admin/orders/AdminOrderViewScreen"

function App() {
   return (
      <ConfigProvider
         theme={{
            token: {
               colorPrimary: "#8CBA38",
               borderRadius: 4,
            },
         }}>
         <StyleProvider hashPriority='high'>
            <BrowserRouter>
               <Routes>
                  <Route path='/dashboard/' element={<Dashboard type='user' />}>
                     <Route path='home' element={<HomeScreen />} />
                     <Route path='orders' element={<OrdersScreen />} />
                     <Route
                        path='orders/:orderId'
                        element={<OrderViewScreen />}
                     />
                     <Route path='projects' element={<ProjectsScreen />} />
                     <Route path='profile' element={<ProfileScreen />} />
                     <Route
                        path='projects/:id'
                        element={<ViewProjectScreen />}
                     />
                     <Route
                        path='projects/quotations/:id'
                        element={<ViewQuotationScreen />}
                     />

                     <Route index element={<Navigate to='/dashboard/home' />} />
                  </Route>
                  <Route
                     path='/admin/dashboard'
                     element={<Dashboard type='admin' />}>
                     <Route path='home' element={<AdminHomeScreen />} />
                     <Route path='orders' element={<AdminOrdersScreen />} />
                     <Route
                        path='orders/:orderId'
                        element={<AdminOrderViewScreen />}
                     />
                     <Route path='projects' element={<AdminProjectScreen />} />
                     <Route
                        path='quotations'
                        element={<AdminQuotationsScreen />}
                     />
                     <Route
                        path='quotations/view/:id'
                        element={<AdminViewQuotationScreen />}
                     />
                     <Route path='users' element={<AdminUsersScreen />} />
                     <Route path='admins' element={<AdminsScreen />} />

                     <Route
                        index
                        element={<Navigate to='/admin/dashboard/home' />}
                     />
                  </Route>
                  <Route path='/login' element={<LoginScreen />} />
                  <Route path='/register' element={<RegisterScreen />} />
                  <Route
                     path='/reset-password'
                     element={<ResetPasswordScreen />}
                  />
                  <Route path='*' element={<Navigate to={"/login"} />} />
               </Routes>
            </BrowserRouter>
         </StyleProvider>
      </ConfigProvider>
   )
}

export default App
