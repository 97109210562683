import { Button, Form, Input, message, Modal } from "antd"
import { serverTimestamp } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { Project, ProjectStatus } from "../../../../helpers/interfaces"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../../../helpers/firestoreHelper"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import { useNavigate } from "react-router-dom"

interface AddUpdateProjectProps {
   type: "add" | "update"
   project?: Project
}

export default function AddUpdateProject({
   type,
   project,
}: AddUpdateProjectProps) {
   const [open, setOpen] = useState(false)
   const [loading, setLoading] = useState(false)
   const [form] = Form.useForm()
   const navigate = useNavigate()

   useEffect(() => {
      form.setFieldsValue({ name: project?.name })
   }, [project])

   return (
      <>
         <Modal
            footer={null}
            title={`${type === "add" ? "Add" : "Update"} Project`}
            open={open}
            onCancel={() => setOpen(false)}>
            <Form
               onFinish={async (v) => {
                  if (loading) return
                  setLoading(true)
                  if (type === "add") {
                     try {
                        const response = await addCustomDocument("projects", {
                           name: v.name,
                           userId: getCurrentUserId(),
                           createdAt: serverTimestamp(),
                           status: ProjectStatus.created,
                        })

                        navigate("/dashboard/projects/" + response.id, {
                           state: {
                              showNewQuotation: true,
                           },
                        })

                        message.success("Project added successfully")

                        form.setFieldsValue({ name: "" })
                        setOpen(false)
                     } catch (e) {
                        message.error("Error adding project")
                        console.log(e)
                     }
                  } else {
                     //update project
                     try {
                        const response = await updateCustomDocument(
                           "projects",
                           project?.id,
                           {
                              name: v.name,
                           }
                        )

                        message.success("Project updated successfully")
                        setOpen(false)
                     } catch (e) {
                        message.error("Error updating project")
                     }
                  }
                  setLoading(false)
               }}
               form={form}
               size='large'
               layout='vertical'
               className='p-5'>
               <Form.Item
                  label='Project Name'
                  name={"name"}
                  rules={[
                     {
                        required: true,
                        message: "Please enter a name for the project",
                     },
                     {
                        min: 3,
                        message: "Name must be at least 3 characters",
                     },
                     {
                        max: 50,
                        message: "Name must be less than 50 characters",
                     },
                  ]}>
                  <Input placeholder='Enter Project Name' />
               </Form.Item>

               <Form.Item>
                  <Button loading={loading} type='primary' htmlType='submit'>
                     {type === "add" ? "Add Project" : "Update Project"}
                  </Button>
               </Form.Item>
            </Form>
         </Modal>
         <Button
            type={type === "add" ? "primary" : "link"}
            onClick={() => setOpen(true)}>
            {type === "add" ? "Add Project" : "Update Project"}
         </Button>
      </>
   )
}
