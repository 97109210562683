import { Form, Select } from "antd"
import React from "react"
import { quotationRequestIssueRejectionType } from "../../../../helpers/data/projectData"

export default function QuotationRequestIssueFormField() {
   return (
      <Form.Item
         label='Reason for Rejection'
         name={"rejectionType"}
         rules={[
            {
               required: true,
               message: "Please select rejection reason",
            },
         ]}>
         <Select
            allowClear
            placeholder='Select rejection reason'
            options={Object.keys(quotationRequestIssueRejectionType).map(
               (k) => {
                  return {
                     label: quotationRequestIssueRejectionType[k],
                     value: k,
                  }
               }
            )}
         />
      </Form.Item>
   )
}
