import React from "react"
import { FirestoreDate } from "../../../../helpers/interfaces"

export default function DateRenderer({
   date,
   full = false,
}: {
   date: FirestoreDate
   full?: boolean
}) {
   if (date) {
      const dateObj = new Date(date.seconds * 1000)
      return (
         <div>
            {full ? dateObj.toLocaleString() : dateObj.toLocaleDateString()}
         </div>
      )
   }

   return <div>No-Date</div>
}
