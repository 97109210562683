import { Form, Select } from "antd"
import { collection, getFirestore } from "firebase/firestore"
import React from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { User } from "../../../../helpers/interfaces"

export default function UserFormField() {
   const [usersSnapshot, usersLoading, usersError] = useCollection(
      collection(getFirestore(), "users")
   )

   const usersData: User[] =
      usersSnapshot?.docs?.map((d) => {
         // @ts-ignore
         return {
            id: d.id,
            ...d.data(),
         } as User
      }) ?? []
   return (
      <Form.Item name={"userId"}>
         <Select
            allowClear
            placeholder={"Select user"}
            loading={usersLoading}
            options={usersData.map((u) => ({
               label: u.fullname,
               value: u.id,
            }))}
         />
      </Form.Item>
   )
}
