import { CheckCircleOutlined } from "@ant-design/icons"
import { Alert, Button, Form, Input } from "antd"
import { getAuth } from "firebase/auth"
import React, { useState } from "react"
import {
   useAuthState,
   useSendPasswordResetEmail,
} from "react-firebase-hooks/auth"
import { Link, Navigate, useNavigate } from "react-router-dom"
import Loading from "../Loading"

export default function ResetPasswordScreen() {
   const navigate = useNavigate()
   const [currentUser, loadingUser, userError] = useAuthState(getAuth())
   const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(
      getAuth()
   )

   const [success, setSuccess] = useState(false)

   if (loadingUser) return <Loading />

   if (currentUser) return <Navigate to='/dashboard' />

   return (
      <div
         className='h-full w-full flex flex-row justify-end bg-no-repeat bg-cover'
         style={{
            backgroundImage: `url("${require("../../assets/accountback.png")}")`,
         }}>
         <div className='mr-[100px] flex flex-col justify-center items-center'>
            <div className='w-[400px] rounded-lg bg-white h-auto flex flex-col py-10 px-5'>
               {success ? (
                  <div className='flex flex-col items-center space-y-4'>
                     <CheckCircleOutlined className='text-[100px] text-primary font-light' />
                     <h1 className='text-lg'>Email Link Sent Successfully.</h1>

                     <div className='w-full h-[1px] bg-gray-200' />

                     <Button
                        size='large'
                        onClick={() => {
                           navigate("/login")
                        }}
                        className='w-full'>
                        LOGIN NOW
                     </Button>
                  </div>
               ) : (
                  <>
                     <div className='space-y-4 mb-4'>
                        <h2 className='text-3xl mb-2 font-semibold text-center'>
                           Welcome to Evergo Packaging
                        </h2>
                        <h2 className='text-xl mb-2'>Forgot your password?</h2>
                     </div>

                     <Form
                        layout='vertical'
                        size='large'
                        onFinish={async (v) => {
                           const response = await sendPasswordResetEmail(
                              v.email
                           )

                           if (response) {
                              setSuccess(true)
                           }
                        }}
                        name='loginForm'>
                        <div className='flex flex-col'>
                           <Form.Item
                              name={"email"}
                              rules={[
                                 {
                                    required: true,
                                    message: "Please input your email address",
                                 },
                                 {
                                    type: "email",
                                    message:
                                       "Please input a valid email address",
                                 },
                              ]}>
                              <Input placeholder='Enter your email address' />
                           </Form.Item>
                        </div>
                        {error && (
                           <Alert
                              type='error'
                              message={error.message}
                              showIcon
                           />
                        )}
                        <div className='mt-5 space-y-3 flex flex-col items-center'>
                           <Button
                              type='primary'
                              htmlType='submit'
                              loading={sending}
                              className='w-full'>
                              SEND RESET LINK
                           </Button>

                           <div className='w-full h-[1px] bg-gray-200' />

                           <Button
                              onClick={() => {
                                 navigate("/login")
                              }}
                              className='w-full'>
                              LOGIN NOW
                           </Button>
                        </div>
                     </Form>
                  </>
               )}
            </div>
         </div>
      </div>
   )
}
