import { DeliveryAddress, FirestoreDate } from "../interfaces"

export type StartOrderData = {
   quoteId: string
   projectId: string
   pickupOption: OrderPickupOption
   paymentOption: OrderPaymentOptions
   bankPayment?: OrderBankPayment
   deliveryAddress?: DeliveryAddress
   files: string[]
   description: string
}

export interface OrderBankPayment {
   attachments: string[]
}

export enum OrderStatus {
   created = "created",
   submitted = "submitted",
   customerReviewPending = "customerReviewPending",
   adminReviewPending = "adminReviewPending",
   approved = "approved",
}

export type Order = {
   ref?: string
   id: string
   createdAt: FirestoreDate
   updatedAt: FirestoreDate
   userId: string
   projectId: string
   quoteId: string
   status: OrderStatus
   title: string
   deliveryDate: Date
   paymentOption: OrderPaymentOptions
   pickupOption: OrderPickupOption | null
   bankPayment?: OrderBankPayment
   deliveryAddress?: DeliveryAddress
   totalPrice: number
}

export enum OrderPickupOption {
   self = "self",
   delivery = "delivery",
}

export enum OrderPaymentOptions {
   cash = "cash",
   bankTransfer = "bankTransfer",
}

export interface OrderActivity {
   description: string
   createdAt: FirestoreDate
   updatedAt: FirestoreDate
   orderId: string
   userId: string
   from: "user" | "admin"
   type: OrderActivityType
   attachedFiles: string[]
}

export enum OrderActivityType {
   orderCreated = "orderCreated",
   orderDelivered = "orderDelivered",
   orderRejected = "orderRejected",
   orderAccepted = "orderAccepted",
   message = "message",
}

export interface OrderSetting {
   shippingFee: number
}
