import React from "react"
import { QuotationRequest, UserView } from "../../../helpers/interfaces"
import Title from "./Title"
import SubTtitle from "./SubTtitle"
import { CheckCircleFilled } from "@ant-design/icons"
import DateRenderer from "../user/combined/DateRenderer"
import ImageAssetPreview from "./ImageAssetPreview"

export default function QuoteRequestAcceptanceView({
   view,
   quoteRequest,
}: {
   view: UserView
   quoteRequest: QuotationRequest
}) {
   return (
      <div className='rounded-lg flex flex-col p-2 space-y-4 border border-green-500 shadow-lg'>
         <div className='flex flex-row justify-between text-base text-green-600'>
            <div className='flex space-x-2 flex-row '>
               <CheckCircleFilled className='text-lg' />
               <Title text='Quotation Request Accepted' />
            </div>
            <div>
               <DateRenderer full date={quoteRequest.updatedAt} />
            </div>
         </div>
         <div className='text-green-600 font-semibold text-lg  flex flex-row  justify-between border border-gray-400 shadow-md rounded-lg p-2'>
            <h1 className='m-0 self-center'>
               Delivery: {quoteRequest.acceptance.deliveryDays} Days
            </h1>

            <div className='w-[1px] bg-gray-400' />
            <h1 className='m-0 self-center'>
               Total Amount: ${quoteRequest.acceptance.totalPrice.toFixed(2)}
            </h1>
         </div>

         <div className='flex flex-col space-y-2'>
            <SubTtitle text='Pricing' />
            <div className='relative overflow-x-auto text-base border border-gray-300 rounded-lg'>
               <table className='w-full text-left text-gray-500 '>
                  <thead className='text-gray-700 uppercase bg-gray-50 '>
                     <tr>
                        <th className='px-3 py-2' scope='row'>
                           Quantity
                        </th>
                        <th className='px-3 py-2' scope='row'>
                           Price
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {quoteRequest.acceptance.pricing.map((pricing, index) => (
                        <tr
                           className='bg-white border-b '
                           key={"pricing-" + index}>
                           <th
                              scope='row'
                              className='px-3 py-2 font-medium text-gray-900 whitespace-nowrap '>
                              {pricing.qty}
                           </th>
                           <td className='px-3 py-2'>
                              {pricing.price.toFixed(2)}
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
         {quoteRequest.acceptance.remarks &&
         quoteRequest.acceptance.remarks.length > 0 ? (
            <div className='flex flex-col space-y-2'>
               <SubTtitle text='Remarks' />
               <p className='m-0'>{quoteRequest.acceptance.remarks}</p>
            </div>
         ) : (
            <></>
         )}

         <div className='flex flex-row space-x-2 flex-wrap'>
            {quoteRequest.files.map((file, index) => (
               <ImageAssetPreview
                  allowDownload={true}
                  userId={quoteRequest.userId}
                  key={"quote-file-" + index}
                  id={file}
               />
            ))}
         </div>
      </div>
   )
}
