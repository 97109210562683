import { Button, Image, message } from "antd"
import {
   getBlob,
   getDownloadURL,
   getMetadata,
   getStorage,
   ref,
} from "firebase/storage"
import React, { useEffect, useState } from "react"
import { DownloadOutlined } from "@ant-design/icons"
import { saveAs } from "file-saver"
import Loading from "../../Loading"
export default function ImageAssetPreview({
   id,
   userId,
   allowDownload = false,
   borderLess = false,
   allowPreview = true,
}: {
   id: string
   userId: string
   allowDownload?: boolean
   borderLess?: boolean
   allowPreview?: boolean
}) {
   const [loading, setLoading] = useState(true)
   const [assetUrl, setAssetUrl] = useState("")
   const [downloading, setDownloading] = useState(false)
   const assetRef = ref(getStorage(), userId + "/" + id)

   const tryGetDownloadUrl = async () => {
      const du = await getDownloadURL(assetRef)
      return du
   }

   useEffect(() => {
      const loadAsset = async () => {
         const du = await tryGetDownloadUrl()
         setAssetUrl(du)
         setLoading(false)
      }
      loadAsset()
   }, [id])

   if (loading)
      return (
         <div className='flex items-center justify-center w-[51px] h-[51px] border-dashed border-2'>
            <Loading />
         </div>
      )

   if (allowDownload) {
      return (
         <div className='flex flex-col space-y-2 rounded-md pt-2 border-gray-300 border items-center'>
            <Image
               preview={allowPreview}
               width={80}
               height={80}
               onError={(a) => {
                  console.log(a)
               }}
               className='w-[50px] h-[50px] '
               src={assetUrl}
            />
            <div className=' border-gray-300 p-1 border-t border-b-0 flex flex-row justify-between space-x-4'>
               <h1>Download</h1>
               <Button
                  loading={downloading}
                  size='small'
                  type='primary'
                  onClick={async () => {
                     setDownloading(true)
                     try {
                        const md = await getMetadata(assetRef)

                        const blob = await getBlob(assetRef)
                        message.success("File downloaded...")

                        saveAs(blob, md.name)
                     } catch (e) {
                        console.log(e)
                        message.error("Error downloading file")
                     }

                     setDownloading(false)
                  }}
                  icon={<DownloadOutlined />}
               />
            </div>
         </div>
      )
   }
   return (
      <div
         className={
            "p-1 w-fit" + (borderLess ? "bg-black" : "border-dashed border-2")
         }>
         <Image
            width={80}
            preview={allowPreview}
            height={80}
            className={"w-[50px] h-[50px] "}
            src={assetUrl}
         />
      </div>
   )
}
