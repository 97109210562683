export interface Project {
   id: string
   name: string
   createdAt: FirestoreDate
   status: ProjectStatus
   userId: string
}

export enum ProjectStatus {
   created = "created",
   submitted = "submitted",
   customerReviewPending = "Customer Review Pending",
   rejected = "rejected",
   approved = "approved",
}

export interface FirestoreDate {
   seconds: number
   nanoseconds: number
}

export enum QuoteStatus {
   submitted = "submitted",
   modificationRequested = "modificationRequested",
   replaced = "replaced",
   accepted = "accepted",
   orderCreated = "orderCreated",
}

export enum QuoteRequestStatus {
   submitted = "submitted",
   awaiting = "awaiting",
   accepted = "accepted",
   rejected = "rejected",
}

export interface Quotation {
   id: string
   projectId: string
   userId: string
   title: string
   createdAt: FirestoreDate
   status: QuoteStatus
   updatedAt: FirestoreDate
   acceptedQuoteRequestId: string
   orderId?: string
}

export enum QuotationRequestType {
   submission = "submission",
   modification = "modification",
}

export enum QuotationRequestIssueRejectionType {
   date = "date",
   quantity = "quantity",
   other = "other",
}

export interface QuotationRequestIssue {
   type: QuotationRequestIssueRejectionType
   remarks: string
}

export interface Pricing {
   qty: number
   price: number
}

export interface Acceptance {
   pricing: Pricing[]
   remarks?: string
   totalPrice: number
   deliveryDays: number
   quoteRequestId: string
}

export interface QuotationRequest {
   id: string
   projectId: string
   userId: string
   quoteId: string
   createdAt: FirestoreDate
   updatedAt: FirestoreDate
   submitType: "user" | "anonymous"
   status: QuoteRequestStatus
   type: QuotationRequestType
   issue: QuotationRequestIssue

   // acceptance
   acceptance: Acceptance

   // Quotation data
   name: string
   description: string
   productType: "existing" | "custom"
   numberOfPrintVersion: string
   turnaroundTime: "1" | "2" | "3" | "4" | "5"
   length: number
   width: number
   height: number
   qty: number[]

   shippingOption: "sp" | "qfe" | "tbd"
   packagingStyle: string
   paperStock: string
   outsidePrintSpec: string
   outsideCoating: string
   insidePrintSpec: string
   insideCoating: string
   specialEffects: string[]
   additionalDetails: string

   files: string[]
   adminId?: string
   debug: boolean
}

export interface Admin {
   id?: string
   email: string
   name: string
   disabled: boolean
}

export interface IAddUpdateAdmin {
   email: string
   name: string
   password: string
}

export interface ServerResponse {
   isSuccess: boolean
   message: string
   data?: any
}

export const okResponse = (message: string, data?: any): ServerResponse => ({
   isSuccess: true,
   message,
   data,
})

export const errorResponse = (message: string, data?: any): ServerResponse => ({
   isSuccess: false,
   message,
   data,
})

export interface User {
   id: string
   createdAt: FirestoreDate
   fullname: string
   isEnabled: boolean
   role: string
   updatedAt: FirestoreDate
}

export enum UserView {
   admin = "admin",
   user = "user",
}
export interface DeliveryAddress {
   name: string
   address1: string
   address2?: string
   city: string
   state: string
   postalCode: string
}

export interface UserProfile {
   email: string
   fullname: string
   phoneNumber: string
   image?: string
}

export interface EvergoUser {
   id: string
   fullname: string
   email: string
   updatedAt: FirestoreDate
}
