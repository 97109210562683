import React from "react"
import Loading from "../../../Loading"

export default function StatsCard({
   title = "",
   value = "",
   icon = <></>,
   loading = false,
   iconColorClassName = "text-black border-black",
}: {
   title: string
   value: string
   icon: React.ReactNode
   loading?: boolean
   iconColorClassName?: string
}) {
   return (
      <Loading loading={loading}>
         <div className='flex flex-col p-4 rounded-lg border border-solid shadow-md w-full'>
            <h1 className='text-lg'>{title}</h1>
            <div className='flex flex-row justify-between items-center mt-10'>
               <h1 className='text-2xl font-bold text-primary'>{value}</h1>
               <div
                  className={
                     "text-lg  p-2 rounded border-solid border h-[35px] flex items-center justify-center w-[35px] " +
                     iconColorClassName
                  }>
                  {icon}
               </div>
            </div>
         </div>
      </Loading>
   )
}
