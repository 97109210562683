import { OrderActivity } from "../../../../../helpers/types/order"
import ActivityRootView from "./ActivityRootView"
import DateRenderer from "../../combined/DateRenderer"
import ImageAssetPreview from "../../../combined/ImageAssetPreview"
import { Tag } from "antd"

interface Props {
   userType: "admin" | "user"
   activity: OrderActivity
   title: string
}

export default function OrderCreationActivityView({
   title,
   activity,
   userType,
}: Props) {
   return (
      <ActivityRootView
         headerColorClass='bg-blue-500 text-white font-bold'
         header={
            userType === "user"
               ? "You created this order"
               : `User created this order`
         }>
         <div className='flex flex-col space-y-2'>
            <div className='flex flex-row justify-between items-center'>
               <h1 className='font-semibold text-base'>{title}</h1>
               <div className='flex flex-row items-center space-x-2'>
                  <DateRenderer full date={activity.createdAt} />
                  <Tag color='blue-inverse'>CREATED</Tag>
               </div>
            </div>
            <p className='text-justify'>{activity.description}</p>
            <h1 className='font-semibold'>Attached Files</h1>
            {(activity.attachedFiles ?? []).length > 0 && (
               <div className='flex flex-row flex-wrap gap-2'>
                  {activity.attachedFiles?.map?.((file) => (
                     <ImageAssetPreview id={file} userId={activity.userId} />
                  ))}
               </div>
            )}
         </div>
      </ActivityRootView>
   )
}
