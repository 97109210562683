import { Form, Select } from "antd"
import React from "react"
import { quotationOptions } from "../../../helpers/defaultData"

export default function QuoteStatusFormField() {
   return (
      <Form.Item name={"quoteStatus"}>
         <Select
            allowClear
            placeholder='Select quote'
            options={quotationOptions}
         />
      </Form.Item>
   )
}
