import React, { useState } from "react"
import QuotationRequestStatusRenderer from "../user/combined/QuotationRequestStatusRenderer"
import {
   ProjectStatus,
   QuotationRequest,
   QuoteRequestStatus,
   UserView,
} from "../../../helpers/interfaces"
import { Button, message } from "antd"
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons"
import ViewQuotationDetails from "../../viewquotation/ViewQuotationDetails"
import { turnaroundTime } from "../../../helpers/data/projectData"
import UpdateQuotationRequest from "../user/viewprojects/UpdateQuotationRequest"
import { getCurrentUserId } from "../../../helpers/userAuthHelpers"
import { query, serverTimestamp } from "firebase/firestore"
import {
   addCustomDocument,
   updateCustomDocument,
} from "../../../helpers/firestoreHelper"
import ImageAssetPreview from "./ImageAssetPreview"

export default function QuoteRequestAwaitingView({
   quotationRequest,
   view,
}: {
   quotationRequest: QuotationRequest
   view: UserView
}) {
   const [showDetail, setShowDetail] = useState(false)
   return (
      <div className='flex flex-col space-y-4 shadow-md border border-solid p-3 rounded-lg'>
         <div className='flex flex-row justify-between items-center'>
            <h2 className='font-bold text-lg'>{quotationRequest.name}</h2>
            <QuotationRequestStatusRenderer status={quotationRequest?.status} />
         </div>
         <div className='grid grid-cols-1 lg:grid-cols-2'>
            <div className='flex flex-col'>
               <h1 className='font-semibold text-sm'>Description</h1>
               <p className='text-base'>{quotationRequest.description}</p>
            </div>
            <div className='flex justify-end flex-row space-x-2'>
               <Button
                  type='primary'
                  onClick={() => setShowDetail(!showDetail)}
                  icon={showDetail ? <EyeInvisibleFilled /> : <EyeFilled />}>
                  View
               </Button>
               {view === UserView.user &&
                  (quotationRequest.status === QuoteRequestStatus.awaiting ||
                     quotationRequest.status ===
                        QuoteRequestStatus.submitted) && (
                     <UpdateQuotationRequest
                        quotationRequestId={quotationRequest.id}
                        onUpdate={async (q) => {
                           delete q.id
                           q.userId = getCurrentUserId()
                           q.projectId = quotationRequest.projectId
                           // @ts-ignore
                           q.updatedAt = serverTimestamp()
                           q.submitType = "user"

                           try {
                              message.info("Updating quote")

                              await updateCustomDocument(
                                 "quote-requests",
                                 quotationRequest.id,
                                 q
                              )

                              await updateCustomDocument(
                                 "projects",
                                 quotationRequest.projectId,
                                 {
                                    status: ProjectStatus.submitted,
                                 }
                              )

                              message.success("Quote added")

                              return true
                           } catch (e) {
                              console.log(e)
                              message.error("Error updating quote")
                              return false
                           }
                        }}
                     />
                  )}
            </div>
         </div>
         <div className=''>
            <ViewQuotationDetails show={showDetail} quote={quotationRequest} />
         </div>
         <div className='flex flex-row justify-between items-center mx-2'>
            {quotationRequest.files.length === 0 ? (
               <h1>No Assets Attached</h1>
            ) : (
               <></>
            )}
            <div className='flex flex-row space-x-2 flex-wrap'>
               {quotationRequest.files.map((file, index) => (
                  <ImageAssetPreview
                     allowDownload={true}
                     userId={quotationRequest.userId}
                     key={"quote-file-" + index}
                     id={file}
                  />
               ))}
            </div>
            <div className='flex flex-col space-y-1'>
               <h1 className='m-0 text-sm text-center font-semibold'>
                  Due Date
               </h1>
               <h2 className='text-lg font-semibold m-0'>
                  {turnaroundTime[quotationRequest.turnaroundTime]}
               </h2>
            </div>
         </div>
      </div>
   )
}
