import {
   Button,
   ButtonProps,
   Checkbox,
   Form,
   Input,
   InputNumber,
   message,
   Modal,
   Radio,
   Select,
   Upload,
} from "antd"
import React, { useEffect, useRef, useState } from "react"
import {
   insideCoating,
   insidePrintSpec,
   outsideCoating,
   outsidePrintSpecifications,
   packagingStyle,
   paperStock,
   specialEffects,
   turnaroundTime,
} from "../../../../helpers/data/projectData"
import { QuotationRequest } from "../../../../helpers/interfaces"
import {
   getStorage,
   ref,
   uploadBytesResumable,
   UploadTask,
} from "firebase/storage"
import { getCurrentUserId } from "../../../../helpers/userAuthHelpers"
import { MinusCircleOutlined } from "@ant-design/icons"
import ImageUploader from "../../combined/ImageUploader"
import { UploadType } from "../../../../helpers/uploadFileHelper"

interface AddUpdateQuotationProps {
   type: "add" | "update"
   quotation?: QuotationRequest
   onAdd?: (quotation: QuotationRequest) => Promise<boolean>
   onUpdate?: (quotation: QuotationRequest) => Promise<boolean>
   showInitial: boolean
   buttonType: "link" | "primary" | "dashed" | "ghost" | "text"
   buttonProps?: ButtonProps
   buttonText?: string
}

export default function AddUpdateQuotation({
   type,
   quotation,
   onAdd,
   showInitial,
   onUpdate,
   buttonType,
   buttonProps = {},
   buttonText,
}: AddUpdateQuotationProps) {
   const [open, setOpen] = useState(showInitial ? true : false)
   const [form] = Form.useForm()
   const [quoteAdding, setQuoteAdding] = useState(false)
   const [uploadedFiles, setUploadedFiles] = useState([])
   const [fileUploading, setFileUploading] = useState(false)

   useEffect(() => {
      if (quotation) {
         form.setFieldsValue(quotation)
         // newFileList.current = quotation.files.map((f) => {
         //    return {
         //       filename: f,
         //       uid: f,
         //       uploaded: true,
         //       uploading: false,
         //       uploadTask: null,
         //    }
         // })
         // setUploadedFilesx(newFileList.current)
      }
   }, [quotation])

   return (
      <>
         <Modal
            cancelButtonProps={{
               disabled: quoteAdding,
            }}
            width={1000}
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            title={
               type === "add" ? "Request New Quotation" : "Update Quotation"
            }>
            <Form
               // initialValues={{
               //    name: "Test",
               //    productType: "existing",
               //    description: "Testaaaaaaaa",
               //    qty: [1],
               //    numberOfPrintVersion: "1",
               //    turnaroundTime: "1",
               //    length: 1,
               //    width: 1,
               //    height: 1,
               //    packagingStyle: "AUTO LOCK TUCK BACK - A100-2",
               //    paperStock: "18PT Recycled Stock",
               //    outsidePrintSpec: "1 Colour Black",
               //    outsideCoating: "Semi Gloss Aqueous Coating",
               //    insidePrintSpec: "4 Colour Process",
               //    insideCoating: "Gloss Lamination",
               //    specialEffects: ["None"],
               //    additionalDetails: "2222",
               // }}
               size='large'
               layout='vertical'
               form={form}
               className='m-4'
               onFinish={async (v) => {
                  setQuoteAdding(true)
                  v.files = uploadedFiles.map((f) => f.filename)

                  if (!v.specialEffects) {
                     v.specialEffects = []
                  }

                  if (!v.additionalDetails) {
                     v.additionalDetails = ""
                  }

                  if (type === "add") {
                     const response = await onAdd?.(v as QuotationRequest)

                     if (response) {
                        setOpen(false)
                     } else {
                        message.error("Error adding quotation request")
                     }
                  } else {
                     const response = await onUpdate?.(v as QuotationRequest)
                     if (response) {
                        setOpen(false)
                     } else {
                        message.error("Error updating quotation")
                     }
                  }

                  setQuoteAdding(false)
               }}>
               <div className='flex flex-row space-x-2'>
                  <Form.Item
                     name='name'
                     className='flex-1'
                     label='Name'
                     rules={[
                        {
                           required: true,
                           message: "Please enter quotation name",
                        },
                     ]}>
                     <Input placeholder='Enter quotation name' />
                  </Form.Item>
               </div>

               <Form.Item
                  name='description'
                  label='Description'
                  rules={[
                     {
                        required: true,
                        message: "Please enter quotation description",
                     },
                     {
                        min: 10,
                        message: "Description must be atleast 10 characters",
                     },
                     {
                        max: 2000,
                        message:
                           "Description must be less than 2000 characters",
                     },
                  ]}>
                  <Input.TextArea placeholder='Enter quotation description' />
               </Form.Item>

               <Form.List name={"qty"}>
                  {(fields, { add, remove }) => (
                     <div className='mb-2'>
                        {fields.map((field, index) => (
                           <div className='flex flex-row items-end space-x-4'>
                              <Form.Item
                                 label={"QTY " + (index + 1)}
                                 className='flex-1'
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please enter QTY",
                                       type: "number",
                                    },
                                    {
                                       min: 1,
                                       message: "QTY must be greater than 0",
                                       type: "number",
                                    },
                                 ]}
                                 {...field}>
                                 <InputNumber
                                    min={0}
                                    placeholder='Enter QTY'
                                    className='w-full'
                                    style={{
                                       width: "100%",
                                    }}
                                 />
                              </Form.Item>
                              <Button
                                 className='mb-6'
                                 danger
                                 onClick={() => remove(index)}>
                                 Remove
                              </Button>
                           </div>
                        ))}

                        <Button
                           onClick={() => add()}
                           className='w-full'
                           type='dashed'>
                           Add More Quantities
                        </Button>
                     </div>
                  )}
               </Form.List>

               <div className='flex flex-row space-x-2'>
                  <Form.Item
                     className='flex-1'
                     name='numberOfPrintVersion'
                     label='Number of Print Version'
                     rules={[
                        {
                           required: true,
                           message: "Please enter number of print version",
                        },
                     ]}>
                     <Input placeholder='Enter number of print version' />
                  </Form.Item>

                  <Form.Item
                     className='flex-1'
                     name='turnaroundTime'
                     label='Turnaround Time'
                     rules={[
                        {
                           required: true,
                           message: "Please Select Turn Around Time",
                        },
                     ]}>
                     <Select
                        options={Object.keys(turnaroundTime).map((k) => {
                           return { label: turnaroundTime[k], value: k }
                        })}
                        placeholder='Select Turn Around Time'
                     />
                  </Form.Item>
               </div>

               <div className='flex flex-row space-x-2'>
                  <Form.Item
                     className='flex-1'
                     name='length'
                     label='Length (inches)'
                     rules={[
                        {
                           required: true,
                           min: 0,
                           message: "Please enter length",
                           type: "number",
                        },
                     ]}>
                     <InputNumber
                        min={0}
                        style={{
                           width: "100%",
                        }}
                        placeholder='Enter length'
                     />
                  </Form.Item>
                  <Form.Item
                     className='flex-1'
                     name='width'
                     label='Width (inches)'
                     rules={[
                        {
                           required: true,
                           min: 0,
                           type: "number",
                           message: "Please enter width",
                        },
                     ]}>
                     <InputNumber
                        style={{
                           width: "100%",
                        }}
                        min={0}
                        placeholder='Enter width'
                     />
                  </Form.Item>
                  <Form.Item
                     className='flex-1'
                     name='height'
                     label='Height (inches)'
                     rules={[
                        {
                           required: true,
                           min: 0,
                           type: "number",
                           message: "Please enter height",
                        },
                     ]}>
                     <InputNumber
                        style={{
                           width: "100%",
                        }}
                        min={0}
                        placeholder='Enter height'
                     />
                  </Form.Item>
               </div>
               <div className='flex flex-row space-x-2'>
                  <Form.Item
                     className='flex-1'
                     name='packagingStyle'
                     label='Packaging Style'
                     rules={[
                        {
                           required: true,
                           message: "Please select packaging style",
                        },
                     ]}>
                     <Select
                        showSearch
                        allowClear
                        options={packagingStyle.map((ps) => {
                           return { label: ps, value: ps }
                        })}
                        placeholder='Select packaging style'
                     />
                  </Form.Item>
                  <Form.Item
                     className='flex-1'
                     name='paperStock'
                     label='Paper Stock'
                     rules={[
                        {
                           required: true,
                           message: "Please select paper stock",
                        },
                     ]}>
                     <Select
                        showSearch
                        allowClear
                        options={paperStock.map((ps) => {
                           return { label: ps, value: ps }
                        })}
                        placeholder='Select paper stock'
                     />
                  </Form.Item>
               </div>
               <div className='flex flex-row space-x-2'>
                  <Form.Item
                     className='flex-1'
                     name='outsidePrintSpec'
                     label='Outside Print Specifications'
                     rules={[
                        {
                           required: true,
                           message:
                              "Please select outside print specifications",
                        },
                     ]}>
                     <Select
                        showSearch
                        allowClear
                        options={outsidePrintSpecifications.map((ps) => {
                           return { label: ps, value: ps }
                        })}
                        placeholder='Select outside print specifications'
                     />
                  </Form.Item>
                  <Form.Item
                     className='flex-1'
                     name='outsideCoating'
                     label='Outside Coating'
                     rules={[
                        {
                           required: true,
                           message: "Please select outside coating",
                        },
                     ]}>
                     <Select
                        showSearch
                        allowClear
                        options={outsideCoating.map((ps) => {
                           return { label: ps, value: ps }
                        })}
                        placeholder='Select outside coating'
                     />
                  </Form.Item>
               </div>
               <div className='flex flex-row space-x-2'>
                  <Form.Item
                     className='flex-1'
                     name='insidePrintSpec'
                     label='Inside Print Specifications'
                     rules={[
                        {
                           required: true,
                           message: "Please select inside print specifications",
                        },
                     ]}>
                     <Select
                        showSearch
                        allowClear
                        options={insidePrintSpec.map((ps) => {
                           return { label: ps, value: ps }
                        })}
                        placeholder='Select inside print specifications'
                     />
                  </Form.Item>
                  <Form.Item
                     className='flex-1'
                     name='insideCoating'
                     label='Inside Coating'
                     rules={[
                        {
                           required: true,
                           message: "Please select inside coating",
                        },
                     ]}>
                     <Select
                        showSearch
                        allowClear
                        options={insideCoating.map((ps) => {
                           return { label: ps, value: ps }
                        })}
                        placeholder='Select inside coating'
                     />
                  </Form.Item>
               </div>
               <Form.Item name={"specialEffects"} label='Special Effects'>
                  <Checkbox.Group
                     options={specialEffects.map((e) => {
                        return { label: e, value: e }
                     })}
                  />
               </Form.Item>
               <Form.Item name={"additionalDetails"} label='Additional Details'>
                  <Input.TextArea placeholder='Enter additional details' />
               </Form.Item>

               <div className='flex flex-col gap-2 mb-2'>
                  <label>Upload Files</label>
                  <ImageUploader
                     type={UploadType.quotes}
                     onUploadStateChange={(a) => setFileUploading(a)}
                     userId={getCurrentUserId()}
                     onChange={(f) => setUploadedFiles(f)}
                  />
               </div>

               <Form.Item>
                  <Button
                     disabled={fileUploading}
                     loading={quoteAdding}
                     type='primary'
                     htmlType='submit'>
                     {type === "add" ? "Submit Request" : "Update Quotation"}
                  </Button>
               </Form.Item>
            </Form>
         </Modal>
         <Button
            {...buttonProps}
            onClick={() => setOpen(true)}
            type={buttonType}>
            {buttonText
               ? buttonText
               : type === "add"
               ? "Request Quotation"
               : "Update"}
         </Button>
      </>
   )
}
