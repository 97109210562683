export const quotationOptions = [
   {
      label: "Submitted",
      value: "submitted",
   },
   {
      label: "Modification Requested",
      value: "modificationRequested",
   },
   {
      value: "replaced",
      label: "Replaced",
   },
   {
      value: "accepted",
      label: "Accepted",
   },
   {
      value: "orderCreated",
      label: "Order Created",
   },
]

export const ordersOption = [
   {
      value: "created",
      label: "Created",
   },
   {
      label: "Submitted",
      value: "submitted",
   },
   {
      label: "Customer Review Pending",
      value: "customerReviewPending",
   },
   {
      value: "adminReviewPending",
      label: "Admin Review Pending",
   },
   {
      value: "approved",
      label: "Approved",
   },
]
